import { useState, useEffect, useRef } from 'react';
import './QuizBox.scss';
import { useSpring, a } from 'react-spring';
import confetti from 'canvas-confetti';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const INSERT_RESPONSE = gql`
mutation QuizResponse($text: String!, $userId: ID!, $quizId: ID!, $socialMediaHandle: String!){
    createQuizResponse(input:{data:{quiz: $quizId, user: $userId, text: $text, socialMediaHandle: $socialMediaHandle}}){
        quizResponse{
            id
        }
    }
}`;

const WINNER_QUERY = gql`
query WinnerQuery($userId: ID!, $quizId: ID!){
    quizResponses(where:{user: $userId, quiz: $quizId}){
        text,
        user{
            id,
            username,
            socialMedia,
            socialMediaHandle
        }
    }
}`


const useStyles = makeStyles((theme) => ({
    colorPrimary: {
        color: '#EE4A9B',
    },
    colorSecondary: {
        color: '#04358D'
    }
  }));

export default function QuizBox({data, user, loggedIn, setOpen, isSafari}){
    const classes = useStyles();
    const [over, setOver] = useState();
    const [declared, setDeclared] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const quizContainer = useRef();
    const animate = useRef(false);

    const history = useHistory();

    const [insert, {data: mutationData, error, loading}] = useMutation(INSERT_RESPONSE);
    const [getWinner, {data: winnerData, error: winnerError, loading: winnerLoading}] = useLazyQuery(WINNER_QUERY);
    
    const duration = useRef(Math.floor((new Date(data.endTime) - new Date())/1000));
    if(duration.current < 0){
        duration.current = 0;
    }
    const totalDuration = useRef(Math.floor((new Date(data.endTime) - new Date(data.startTime))/1000));

    const angle = useSpring({
        number: 0, 
        from: {number: (duration.current/totalDuration.current === 1? 6.28255 : 2 * Math.PI * duration.current/totalDuration.current)}, 
        config: {duration: duration.current * 1000},
        onRest: () => setOver(true)
    });
    const timeLeft = useSpring({
        time: 0, 
        from: {time: duration.current}, 
        config: {duration: duration.current * 1000},
        onRest: () => setOver(true)
    });
    

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        };
        
        const callback = (entries) => {
            if(animate.current && entries[0].isIntersecting){
                animate.current = false
                const myConfetti = confetti.create(quizContainer.current, {
                    resize: true
                });
                myConfetti({
                    particleCount: 200,
                    startVelocity: 80,
                    drift: -1,
                    spread: 45,
                    angle: 45,
                    gravity: 1.5,
                    ticks: 50000,
                    origin: {
                        x: 0,
                        y: -0.1
                    },
                });
            }            
        }
        
        const observer = new IntersectionObserver(callback, options);
        observer.observe(quizContainer.current);


        if(user && loggedIn && data.quiz_responses.length){
            setOver(true);
            const text = data.quiz_responses[0].text;
            setSelectedOption(data.quizOption.findIndex(option => option.text === text));
        }
        if(duration.current <= 0){
            setOver(true);
        }

        if((new Date(data.endTime) < new Date())&&data.winner){
            setDeclared(true);
            animate.current = true;
            getWinner({
                variables: {
                    userId: parseInt(data.winner.id),
                    quizId: parseInt(data.id)
                }
            })
        }
    },[]);
    return(
        <div className="quizbox">
            <canvas ref={quizContainer}/>
            <div className="row-1">
                <h2>{data.title.toUpperCase()}</h2>
                {(declared)?
                    null
                    :<div className="indicator">
                        <a.svg viewBox="0 0 100 100">
                            <circle cx="50" cy="50" fill="#3A4F99" r="50"/>
                            <a.path 
                                d={angle.number.to(x => `M50,50 L50,100 A50 50 0 ${x >= Math.PI ? 1 : 0} 1 ${50 * (1 - Math.sin(x))} ${50 * (1 + Math.cos(x))} Z`)}
                                fill="var(--primaryColor)"
                            />
                            <circle cx="50" cy="50" fill="var(--secondaryColor)" r="40"/>
                        </a.svg>
                        <div className="text">
                            <p className="label">TIME REMAINING</p>
                            <a.p className="value">{timeLeft.time.to(n => {
                                    if(n > 86400){
                                        return `${Math.floor(n/86400)}D ${Math.floor((n%86400)/3600)}H`
                                    }else if(n > 3600){
                                        return `${Math.floor(n/3600)}H ${Math.floor((n%3600)/60)}M`
                                    } else return `${Math.floor(n/60)}M ${Math.floor(n%60)}S`
                                })}</a.p>
                        </div>
                    </div>
                }
            </div>
            <p className={winnerData? "winner-question" : ""}>{data.question}</p>
            {winnerData?
                <div className="winner">
                    <div className="correct-answer">
                        <p className="title">CORRECT ANSWER</p>
                        <p className="text">{winnerData.quizResponses[0].text}</p>
                    </div>
                    <div className="user">
                        <p className="title">WINNER</p>
                        <p className="name">{winnerData.quizResponses[0].user.username}</p>
                        <div className="row">
                            <p className="label">{winnerData.quizResponses[0].user.socialMedia}</p>
                            <p className="value">{'@' + winnerData.quizResponses[0].user.socialMediaHandle}</p>
                        </div>
                    </div>
                </div>: null
            }
            {(declared)? 
                null
                :<>
                    <div className="options">
                        {data.quizOption.map((item, index) => 
                            <div 
                                className={`item ${(selectedOption === index)? 'selected' : ''}`}
                                onClick={() => {
                                    if(!over && !loading){
                                        if(selectedOption === index){
                                            setSelectedOption(null);
                                        } else setSelectedOption(index);
                                    }
                                }}
                            >
                                <div className="check"/>
                                <p>{item.text}</p>
                            </div>
                        )}
                    </div>
                    <button 
                        disabled={selectedOption === null}
                        className={over && 'invisible'}
                        onClick={() => {
                            if(!user || !loggedIn){
                                if(isSafari){
                                    history.push("/login");
                                }else{
                                    setOpen(true);
                                }
                                return
                            }
                            if(user && !user.socialMediaHandle){
                                setOpen(true);
                                return
                            }
                            insert({variables: {
                                text: data.quizOption[selectedOption].text,
                                userId: parseInt(user.id),
                                quizId: parseInt(data.id),
                                socialMediaHandle: user.socialMediaHandle
                            }}).then(() => setOver(true)).catch(err => console.log(err))
                        }}
                    >
                        SUBMIT ANSWER
                        {loading? 
                            <div className="progress">
                                <CircularProgress size={"3rem"} color="primary" classes={{colorPrimary: classes.colorPrimary}}/>
                            </div>: null
                        }
                    </button>
                </>
            }
        </div>
    )
}