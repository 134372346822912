import { useEffect, useRef } from 'react';
import './LoginPage.scss';
import {ReactComponent as Facebook} from '../../Assets/Icons/facebook.svg';
import Google from '../../Assets/Icons/google.svg';
// import Panther from '../../Assets/pink-panther-logo.png';
import CONSTANTS from '../../Constants';

export default function LoginPage({setProgress}){

    const element = useRef();

    useEffect(() => {
        setProgress(prog => prog + 60);
    }, []);

    useEffect(() => {
        if(element.current){
            console.log(element.current);
            const remValue = parseFloat(window.getComputedStyle(document.getElementsByTagName('html')[0]).getPropertyValue('font-size'));
            element.current.style.height = (window.innerHeight) + 'px';
        }
    });

    return(
        <div className="login-page" ref={element}>
            <div className="inner">
                {/* <img src={Panther} alt="jaipur pink panther logo" /> */}
                <h1>LOG IN</h1>
                <p>LOG IN USING YOUR FACEBOOK OR GOOGLE ACCOUNT TO ACCESS EXCLUSIVE CONTENT</p>
                <div className="row">
                    <a 
                        className="facebook"
                        href={CONSTANTS.createUrl('/connect/facebook')}
                    >
                        <Facebook />
                    </a>
                    <a 
                        className="google"
                        href={CONSTANTS.createUrl('/connect/google')}
                    >
                        <img src={Google} alt="google icon" />
                    </a>
                </div>
            </div>
            
        </div>
    )
}