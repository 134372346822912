import { useState, useEffect, useRef } from 'react';
import './HomeLandingCarousel.scss';
import { useTransition, a } from "react-spring";
import CONSTANTS from "../../Constants";


export default function HomeLandingCarousel(props){
    const [currentDesktopIndex, setCurrentDesktopIndex] = useState(0);
    const [currentMobileIndex, setCurrentMobileIndex] = useState(0);

    const desktopTransitions = useTransition(currentDesktopIndex, {
        initial: {translateX: '0%'},
        from: {translateX: '100%'},
        enter: {translateX: '0%'},
        leave: {translateX: '-100%'},
    });

    const mobileTransitions = useTransition(currentMobileIndex, {
        initial: {translateX: '0%'},
        from: {translateX: '100%'},
        enter: {translateX: '0%'},
        leave: {translateX: '-100%'},
    });

    useEffect(() => {
        setInterval(() => {
            setCurrentDesktopIndex(index => {
                console.log(index);
                if(index < (props.wideData.length - 1)){
                    return index + 1;
                } else {
                    return 0;
                }
            });
            setCurrentMobileIndex(index => {
                // console.log(index);
                if(index < (props.tallData.length - 1)){
                    return index + 1;
                } else {
                    return 0;
                }
            });
        }, 5000);
    }, []);

    console.log(props.tallData);

    return(
        <div className="home-landing-carousel">
            {desktopTransitions((styles, index) => {
                return <a.div 
                    className="item"
                    style={styles}
                >
                    <img 
                        src={CONSTANTS.createUrl(props.wideData[index].url)} alt={props.wideData[index].alternativeText} 
                        className="desktop"
                    />
                </a.div>
            })}
            {mobileTransitions((styles, index) => {
                return <a.div 
                    className="item"
                    style={styles}
                >
                    <img 
                        src={CONSTANTS.createUrl(props.tallData[index].url)} alt={props.tallData[index].alternativeText} 
                        className="mobile"
                    />
                </a.div>
            })}
        </div>
    )
}