import { useState, useEffect } from 'react';
import './Login.scss';
import { Dialog, makeStyles, Select, MenuItem } from '@material-ui/core';
import Dropdown from '../UI/Dropdown/Dropdown';
import {ReactComponent as Cross} from '../../Assets/Icons/cross.svg';
import {ReactComponent as Facebook} from '../../Assets/Icons/facebook.svg';
import {ReactComponent as Arrow} from '../../Assets/Icons/arrow-right.svg';
import {ReactComponent as DropdownArrow} from '../../Assets/Icons/dropdown-arrow.svg';
import Image from '../../Assets/login-image.png';
import Google from '../../Assets/Icons/google.svg';
import Panther from '../../Assets/pink-panther-logo.png';
import Profile from '../../Assets/Icons/profile.svg';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import CONSTANTS from '../../Constants';
import Loading from '../UI/Loading';
import { useHistory } from 'react-router-dom';

const USER_UPDATE = gql`
mutation UserMutation($socialMedia: ENUM_USERSPERMISSIONSUSER_SOCIALMEDIA!, $handle: String!, $id: ID!){
    updateUser(input:{
        where: {id: $id},
        data: {socialMedia: $socialMedia, socialMediaHandle: $handle}
    }){
        user{
            id,
            username,
            provider,
            socialMedia,
            socialMediaHandle,
          	confirmed,
          	blocked,
          	email,
          	quizzes{
              id
            },
          	quiz_responses{
              id
            },
          	created_at,
          	updated_at
        }
    }
}`;

const USER_QUERY = gql`
query UserQuery($id: ID!){
    user(id:$id){
        username,
        email,
        provider,
        socialMedia,
        socialMediaHandle
    }
}`;

const SOCIAL_MEDIA_OPTIONS = [
    {text: 'facebook', value: 'facebook'},
    {text: 'instagram', value: 'instagram'},
    {text: 'twitter', value: 'twitter'},
]

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        backgroundColor: 'transparent',
    },
    backdropRoot: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        // zIndex: '1299 !important'
    },
    paperWidthFalse: {
        maxWidth: 'calc(100% - 20px)',
        margin: '32px auto'
    },
    dialogRoot: {
        zIndex: '1200 !important'
    }
}));

export default function Login({open, setOpen, pageNumber, setPageNumber, user}){
    const classes = useStyles();
    const history = useHistory();

    const closeHandler = () => {
        setOpen(false);
        function removeURLParameter(url, parameter) {
            //prefer to use l.search if you have a location/link object
            var urlparts = url.split('?');   
            if (urlparts.length >= 2) {
        
                var prefix = encodeURIComponent(parameter) + '=';
                var parts = urlparts[1].split(/[&;]/g);
        
                //reverse iteration as may be destructive
                for (var i = parts.length; i-- > 0;) {    
                    //idiom for string.startsWith
                    if (parts[i].lastIndexOf(prefix, 0) !== -1) {  
                        parts.splice(i, 1);
                    }
                }
        
                return urlparts[0] + (parts.length > 0 ? '?' + parts.join('&') : '');
            }
            return url;
        }        
        const newUrlString = removeURLParameter(document.location.href, 'login');
        const newUrl = new URL(newUrlString);
        history.push(newUrl.pathname + newUrl.search);
    };

    const handleClose = () => {
        closeHandler();
    };

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            BackdropProps={{classes: {root: classes.backdropRoot}}}
            PaperProps={{classes: {root: classes.paperRoot}}}
            classes={{paperWidthFalse: classes.paperWidthFalse, root: classes.dialogRoot}}
            maxWidth={false}
        >
            <div className="login">
                <button onClick={() => setOpen(false)}>
                    <Cross/>
                </button>
                {(pageNumber === 1)? 
                    <PageOne setPageNumber={setPageNumber} setOpen={setOpen}/> : 
                    <PageTwo options={SOCIAL_MEDIA_OPTIONS} setOpen={setOpen} user={user}/>
                }
            </div>
        </Dialog>
    )
}

function PageOne({setPageNumber, setOpen}){
    return(
        <div className="page-one">
            <img src={Panther} alt="jaipur pink panther logo" />
            <h1>LOG IN</h1>
            <p>LOG IN USING YOUR FACEBOOK OR GOOGLE ACCOUNT TO CONTINUE</p>
            <div className="row">
                <button 
                    className="facebook"
                    onClick={() => {
                        window.open(CONSTANTS.createUrl('/connect/facebook'));
                    }}
                >
                    <Facebook />
                </button>
                <button 
                    className="google"
                    onClick={() => {
                        window.open(CONSTANTS.createUrl('/connect/google'));
                    }}
                >
                    <img src={Google} alt="google icon" />
                </button>
            </div>
        </div>
    )
}


const useDropdownStyles = makeStyles((theme) => ({
    selectRoot: {
        color: 'var(--secondaryColor)',
        fontFamily: 'var(--primaryFont)',
        fontSize: '1.3rem',
        padding: '0.45rem 0 0 1rem',
        // padding: '1rem 1rem 0.5rem',
        minHeight: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        border: '1px solid #B7B7B7',
        // marginBottom: '1rem',
        borderRadius: '0.4rem',
        textTransform: 'uppercase'
    },
    
    selectSelect: {
        paddingRight: '0',
        
        '&.MuiSelect-select': {
            paddingRight: '0',
            
            '&:focus': {
                borderRadius: '0.4rem',
                backgroundColor: 'white',
            }

        }
    },

    paperRounded: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },

    selectMenu: {
        maxHeight: '15rem',

        '& .MuiMenuItem-root': {
            color: 'black',
            fontFamily: 'var(--primaryFont)',
            fontSize: '1rem',
            padding: '1rem',

            '&:hover, &.Mui-selected': {
                background: 'var(--primaryColor)',
                color: 'white'
            }
        }
    },

    icon: {
        height: '0.5rem',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '1.25rem',
        '&>path': {
            stroke: 'black',
        }
    }
}));

function PageTwo({setOpen, options, user}){
    const classes = useDropdownStyles();
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState("");
    const [socialMediaValue, setSocialMediaValue] = useState(SOCIAL_MEDIA_OPTIONS[0].value);
    const [socialMediaHandle, setSocialMediaHandle] = useState("");
    console.log(user);

    const [getData, {data, loading, error}] = useLazyQuery(USER_QUERY, {
        variables: {
            id: user ? parseInt(user.id) : null,
        },
        fetchPolicy: "network-only"
    })    

    const [mutation, {data: mutationData, loading: mutationLoading, error: mutationError}] = useMutation(USER_UPDATE);

    useEffect(() => {
        if(data){
            setName(data.user.username);
            setSocialMediaValue(data.user.socialMedia);
            setSocialMediaHandle(data.user.socialMediaHandle);
            setLoaded(true);
        }
    }, [data]);

    useEffect(() => {
        if(user && user.id){
            getData();
        }
    }, [user])

    useEffect(() => {
        if(mutationData && mutationData.updateUser.user){
            setOpen(false);
            console.log(mutationData);
            localStorage.setItem('user', JSON.stringify(mutationData.updateUser.user))
        }
    }, [mutationData])

    return(
        <div className="page-two">
            {(!mutationLoading && loaded)? 
                <> 
                    <img src={`https://ui-avatars.com/api/?name=${user.username}&background=random`} alt="" />
                    <input 
                        type="text" 
                        placeholder="Your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled
                    />
                    <Select
                        value={socialMediaValue}
                        onChange={(e) => setSocialMediaValue(e.target.value)}
                        label="Age"
                        disableUnderline
                        MenuProps={{
                            classes: {list: classes.selectMenu}, 
                            transitionDuration: 0,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            PaperProps: {classes: {rounded: classes.paperRounded}}
                        }}
                        inputProps={{classes: {root: classes.inputRoot}}}
                        classes={{
                            root: classes.selectRoot, 
                            icon: classes.icon, 
                            select: classes.selectSelect, 
                            selectMenu: classes.selectMenu
                        }}
                        IconComponent={DropdownArrow}
                    >
                        {options.map((item, index) => 
                            <MenuItem value={item.value}>{item.text.toUpperCase()}</MenuItem>
                        )}
                    </Select>
                    <input 
                        type="text" 
                        placeholder="Your handle"
                        value={socialMediaHandle}
                        onChange={(e) => setSocialMediaHandle(e.target.value)}
                    />
                    <button 
                        className="submit"
                        onClick={() => {
                            if(name&&socialMediaValue&&socialMediaHandle){
                                mutation({variables: {
                                    socialMedia: socialMediaValue,
                                    handle: socialMediaHandle,
                                    id: parseInt(user.id)
                                }})
                            }
                        }}
                        disabled={!(name && socialMediaHandle && socialMediaValue)}
                    >
                        <p>CONTINUE</p>
                        <Arrow />
                    </button>
                </> : <Loading color="primary" size="medium"/>
            }
        </div>
    )
}