import { useState, useEffect } from 'react';
import './SideNav.scss';
import {Drawer} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Panther from '../../Assets/pink-panther-logo.png';
import {ReactComponent as Cross} from '../../Assets/Icons/cross.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    PaperRoot: {
        width: "100vw",
        maxWidth: "500px",
        padding: "0",
    }
});

export default function SideNav({open, setOpen, ticketsPage, merchPage}){
    const classes = useStyles();
    const [isLargeScreen, setIsLargeScreen] = useState(null);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setOpen(open)
    };

    useEffect(() => {
        const setSize = (event) => {
            window.requestAnimationFrame(() => {
                if(event.target.innerWidth > 768){
                    setIsLargeScreen(true);
                }
            })
        }
        setIsLargeScreen(window.innerWidth > 768);
        window.addEventListener('resize', setSize);
        return () => {
            window.removeEventListener('resize', setSize);
        }
    }, []);

    useEffect(() => {
        if(isLargeScreen === true){
            setOpen(false);
        }
    }, [isLargeScreen]);

    return(
        <Drawer 
            anchor={'right'} 
            open={open} 
            onClose={toggleDrawer(false)} 
            classes={{paper: classes.PaperRoot}}
        >
            <div className="navigation-drawer">
                <div className="row">
                    <img src={Panther} alt="" />
                    <button onClick={() => setOpen(false)}>
                        <Cross />
                    </button>
                </div>
                <p className="title">MENU</p>
                <div className="links">
                    <Link 
                        onClick={(e) => {
                            setOpen(false)
                        }}
                        to="/squad"
                    >SQUAD</Link>
                    <Link 
                        onClick={(e) => {
                            setOpen(false)
                        }}
                        to="/matches"
                    >MATCHES</Link>
                    <Link 
                        onClick={(e) => {
                            setOpen(false)
                        }}
                        to="/standings"
                    >STANDINGS</Link>
                    {merchPage? 
                        <Link 
                            onClick={(e) => {
                                setOpen(false)
                            }}
                            to="/merchandise"
                        >MERCHANDISE
                        </Link> : null
                    }
                    <Link 
                        onClick={(e) => {
                            setOpen(false)
                        }}
                        to="/news"
                    >NEWS</Link>
                    {ticketsPage? 
                        <Link 
                            onClick={(e) => {
                                setOpen(false)
                            }}
                            to="/tickets"
                        >TICKETS</Link>:null
                    }
                    <Link 
                        onClick={(e) => {
                            setOpen(false)
                        }}
                        to="/panther-pack"
                    >PANTHERS PACK</Link>
                </div>
            </div>
        </Drawer>
    )
}