import {useState, useEffect, useRef} from 'react';
import './StandingsMobileTeam.scss';
import {ReactComponent as Trophy} from '../../Assets/Icons/trophy.svg';
import {useSpring, a, config} from 'react-spring';
import CONSTANTS from '../../Constants';

export default function StandingsMobileTeam({team, winner}){
    const [animate, setAnimate] = useState(false); // Starts animations when true

    const ReturnSprings = (value, animate, index) => {
        return useSpring({number: animate? value : 0, config: config.molasses, delay: index * 200})
    };

    const linearSprings = team.data.map((item, index) => ReturnSprings(item.value, animate));

    const element = useRef();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const callback = (entries) => {
            if(!animate && entries[0].isIntersecting){
                setAnimate(true);
            }            
        }
            
        const observer = new IntersectionObserver(callback, options);
        observer.observe(element.current)
    },[]);

    return(
        <div className={`standings-mobile-card ${team.isPanther? 'highlighted' : ''}`} ref={element}>
            <img src={CONSTANTS.createUrl(team.img.url)} alt={team.img.alternativeText} />
            <p className={`large ${team.hasQualified? "qualified" : ""} ${(winner && (team.id === winner.id))? 'winner' : ''}`}>
                {(winner && (team.id === winner.id))? <Trophy /> : null}
                {team.position}
            </p>
            {team.data.map((item, index) => 
                <div className="item">
                    <div className="title">
                        <p className="label">{item.title}</p>
                        <a.p className="value">{linearSprings[index].number.to(x => 
                                x.toFixed(Number.isInteger(item.value)? 0 : 2).toString().padStart(2, '0')
                        )}</a.p>
                    </div>
                    <div className="indicator">
                        <a.div className="value" style={{width: linearSprings[index].number.to(x => 100*x/item.maxValue + '%')}}/>
                    </div>
                </div>
            )}
        </div>
    )
}