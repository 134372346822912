import { useTransition, a, useSprings } from "react-spring";
import CONSTANTS from "../../Constants";
import GradientImage from "../UI/GradientImage/GradientImage";

export default function HomePlayerImages({squadPlayerPos, squadImagePos, screenWidth, players, setProgress}){
    const transitions = useTransition(players, {
        // from: {opacity: 0, transform: 'translateX(100%) scale(0.8)', left: `${window.innerWidth}px`},
        from: {opacity: 0},
        update: (item, index) => {
            if(screenWidth > 500){
                if(index === squadPlayerPos + 1){
                    return {opacity: 0.5, transform: 'translateX(-60%) scale(0.8)', left: `${window.innerWidth}px`}
                } else if(index === squadPlayerPos) {
                    return {opacity: 1, transform: 'translateX(25%) scale(1)', left: `${squadImagePos}px`}
                } else if(index === squadPlayerPos - 1){
                    return {opacity: 0.5, transform: 'translateX(-40%) scale(0.8)', left: '0px'}
                } else if(index < squadPlayerPos){
                    return {opacity: 0, transform: 'translateX(-100%) scale(0.8)', left: `${-0.1 * window.innerWidth}px`}
                } else if(index > squadPlayerPos){
                    return {opacity: 0, transform: 'translateX(100%) scale(0.8)', left: `${window.innerWidth}px`}
                }
            } else{
                if(index === squadPlayerPos) {
                    return {opacity: 1, transform: 'translateX(0%) scale(1)', left: `${squadImagePos}px`}
                } else if(index < squadPlayerPos){
                    return {opacity: 0, transform: 'translateX(0%) scale(0.8)', left: `${-1 * window.innerWidth}px`}
                } else if(index > squadPlayerPos){
                    return {opacity: 0, transform: 'translateX(0%) scale(0.8)', left: `${window.innerWidth}px`}
                }
            }
        },
        leave: {opacity: 0},
        expires: () => {
            if(!players.length){
                return 1
            }
        }
    });



    return(
        transitions((styles, item) => {
            return <a.div style={styles} key={item.squadNumber} onLoad={() => {
                setProgress(prog => prog + 20)
            }}>
                {/* <img src={CONSTANTS.createUrl(item.halfImage.url)} alt=""/> */}
                <GradientImage image={CONSTANTS.createUrl(item.halfImage.url)}/>
            </a.div>
        })
    )
}