import { useEffect } from 'react';
import './TermsAndPrivacy.scss';
import { useQuery, gql } from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import rehype from 'rehype-raw';
import gfm from 'remark-gfm';
import PageTransition from '../../Components/PageTransition/PageTransition';

const TERMS_QUERY = gql`
query TermsQuery{
    termsPage{
         text
    }
}`;


export default function Terms({setProgress}){

    const {data, error, loading} = useQuery(TERMS_QUERY);

    useEffect(() => {
        if(data){
            setProgress(prog => prog + 60)
        }
    }, [data]);

    if(data&&data.termsPage){
        return (
            <div className="terms-and-privacy-page">
                <h1 className="title">TERMS AND CONDITIONS</h1>
                <ReactMarkdown skipHtml remarkPlugins={[gfm]} rehypePlugins={[rehype]}>{data.termsPage.text}</ReactMarkdown>
            </div>
        )
    } else return <PageTransition />
}