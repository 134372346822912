import { useState, useEffect, useRef } from 'react';
import './News.scss';
import Arrow from '../../Assets/Icons/arrow-right.svg';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { useSpring, a } from 'react-spring';
import { useQuery, gql } from '@apollo/client';
import PageTransition from '../../Components/PageTransition/PageTransition';
import NewsItem from '../NewsItem/NewsItem';
import CONSTANTS from '../../Constants';
import { format } from 'date-fns';

const NEWS_QUERY = gql`
query NewsQuery{
    newsLabels{
        id
        name
    },
    newsItems(sort:"date:desc"){
        id
        title,
        image{
            url,
            alternativeText
        },
        text,
        fullText,
        articleUrl,
        isWide,
        date,
        news_label{
            id,
            name
        }
        newsSourceImage{
            url,
            alternativeText
        }
    }
}`;

export default function News({ setProgress }) {
    const [tabs, setTabs] = useState(null);
    const { data, loading, error } = useQuery(NEWS_QUERY);
    console.log(data);

    useEffect(() => {
        if (data) {
            const tabs = [
                {
                    id: -1,
                    name: "Latest"
                }
            ];
            const addendum = data.newsLabels.filter(item =>
                (data.newsItems.findIndex(x => x.news_label.id === item.id) > -1)
            ).map(item => {
                return {
                    id: parseInt(item.id),
                    name: item.name
                }
            });
            setTabs(tabs.concat(addendum));
            setProgress(prog => prog + 60);
        }
    }, [data]);

    if (data && tabs && tabs.length) {
        return (
            <Switch>
                <Route path="/news" exact>
                    <NewsInner tabs={tabs} newsItems={data.newsItems} />
                </Route>
                <Route path="/news/:id">
                    <NewsItem />
                </Route>
            </Switch>
        )
    } else return <PageTransition />
}

function NewsInner({ tabs, newsItems }) {
    const [screenWidth, setScreenWidth] = useState(0);
    const [activeTab, setActiveTab] = useState(null);
    const [tabText, setTabText] = useState(null);
    const [tabItem, setTabItem] = useState(null);
    const [newsRows, setNewsRows] = useState(null);
    const tabContainer = useRef();

    useEffect(() => {
        document.fonts.ready.then(() => {
            setActiveTab(0);
            setTabItem(tabs[0].id);
        });
        const setSize = (event) => {
            setScreenWidth(event.target.innerWidth);
        }
        setScreenWidth(window.innerWidth);
        window.addEventListener('resize', setSize);
        return () => {
            window.removeEventListener('resize', setSize);
        }
    }, []);

    useEffect(() => {
        setTabText(tabContainer.current.children[activeTab]);
    }, [activeTab]);

    useEffect(() => {
        const tabIndex = tabs.findIndex(x => x.id === tabItem);
        let filteredNewsItems = newsItems.filter(item => parseInt(item.news_label.id) === tabItem)
        if (filteredNewsItems.length === 0) {
            filteredNewsItems = newsItems;
        }
        // const wideItems = filteredNewsItems.filter(item => item.isWide);
        // const thinItems = filteredNewsItems.filter(item => !item.isWide);
        // let i = 0; //thinItemCounter
        // let j = 0; //wideItemCounter
        // let currRow = -1; //row for first iteration
        // const rows = [];
        // while(j < wideItems.length){
        //     rows.push([]);
        //     currRow++;
        //     rows[currRow].push(wideItems[j]);
        //     j++;
        //     if(i === thinItems.length && j <= wideItems.length - 1){
        //         j++;
        //         rows[currRow].push(wideItems[j]);
        //     }
        //     for(let count = 0; ((count < 2) && (i < thinItems.length)); count++, i++){
        //         rows[currRow].push(thinItems[i]);
        //     }
        // }
        // if(i < (thinItems.length)){
        //     if(currRow === -1){
        //         currRow++;
        //     }
        //     for(let k = 0; k < (thinItems.length - i); k++){
        //         if(k%3 === 0){
        //             rows.push([]);
        //         }
        //         rows[rows.length - 1].push(thinItems[k+i])
        //     }
        // }
        const rows = [];
        let i = 0;
        for (i; i < filteredNewsItems.length; i++) {
            if ((i % 4) === 0) {
                rows.push([]);
            }
            rows[Math.floor(i / 4)].push(filteredNewsItems[i]);
        }
        setNewsRows(rows);
    }, [tabItem]);


    const ReturnSpring = (item, screenWidth) => {
        return useSpring({ left: item ? item.offsetLeft : 0, width: item ? item.offsetWidth : 1 });
    }

    const tabHighlightSpring = ReturnSpring(tabText, screenWidth);

    return (
        <div className="news-page">
            {tabText ? null : <PageTransition />}
            <div className="section-1">
                <h1>NEWS</h1>
                <div className="tabs" >
                    <div className="tab-row" ref={tabContainer}>
                        {tabs && tabs.map((item, index) =>
                            <p
                                className={`item${(activeTab === index) ? ' active' : ''}`}
                                onClick={() => {
                                    setActiveTab(index);
                                    setTabItem(item.id);
                                }}
                            >{item.name.toUpperCase()}</p>
                        )}
                    </div>
                    <div className="highlight-row">
                        <a.div className="highlighter" style={{ ...tabHighlightSpring }} />
                    </div>
                </div>
            </div>

            <div className="section-2">
                {newsRows && newsRows.map(rowItem =>
                    <div className="row">
                        {rowItem.map(item =>
                            // <div className={`item ${item.isWide? 'wide':''}`}>
                            <div className={`item`}>
                                <div className="imagebox">
                                    <img src={CONSTANTS.createUrl(item.image.url)} alt={item.image.alternativeText} />
                                    <div className="label">
                                        <p className="date">{format(new Date(item.date), 'dd MMM yy')}</p>
                                        <p className="tag">{item.news_label.name}</p>
                                    </div>
                                </div>
                                <div className="textbox">
                                    {(item.newsSourceImage) ?
                                        <img src={CONSTANTS.createUrl(item.newsSourceImage.url)} alt={item.newsSourceImage.alternativeText} />
                                        : <div className="image-filler" />
                                    }
                                    <h3>{item.title}</h3>
                                    <p>{item.text}</p>
                                    <div className="links">
                                        <p>READ MORE</p>
                                        {item.articleUrl ?
                                            <a href={item.articleUrl} target="_blank" rel="noreferrer"><img src={Arrow} alt="" /></a>
                                            : <Link to={`/news/${item.id}`}><img src={Arrow} alt="" /></Link>
                                        }
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}