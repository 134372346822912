
import './GradientImage.scss';

export default function GradientImage({image}){

    return(
        <div className="gradient-image">
           <svg>
                <mask id="mask">
                    <linearGradient id="blueGradient" gradientTransform="rotate(90)">
                        <stop offset="0%"  stopColor="white" stopOpacity="1"/>
                        <stop offset="50%"  stopColor="white" stopOpacity="1"/>
                        <stop offset="98%"  stopColor="white" stopOpacity="0"/>
                        <stop offset="100%"  stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                    <rect id="rect" width="100%" height="100%" fill="url(#blueGradient)" />
                </mask>
                <image x="0" y="0" width="100%" height="100%" href={image} id="image" mask="url(#mask)"/>
            </svg>
        </div>
    )
}