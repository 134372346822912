import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import {setContext} from '@apollo/client/link/context';

const getToken = () => {
    return new Promise((resolve, reject) => {

        const currentJwt = JSON.parse(window.localStorage.getItem('jwt')) || "";
        const callback = () => {
            const jwt = JSON.parse(window.localStorage.getItem('jwt'));
            resolve(jwt);
        }
        window.addEventListener('storage', callback);
        resolve(currentJwt);
    })
}

const httpLink = createHttpLink({
    uri: 'https://api.jaipurpinkpanthers.com/graphql',
    // uri: 'http://localhost:1337/graphql',
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return token? 
        {
            headers: {
                ...headers,
                Authorization: `bearer ${token}`,
            },
        }
        : {
            headers: {
                ...headers,               
            },
        };
});


const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    imageGalleries: {
                        // Don't cache separate results based on
                        // any of this field's arguments.
                        keyArgs: false,
                        // Concatenate the incoming list items with
                        // the existing list items.
                        merge(existing = [], incoming) {
                            return [...existing, ...incoming];
                        },
                    },
                    pantherPackVideos: {
                        // Don't cache separate results based on
                        // any of this field's arguments.
                        keyArgs: false,
                        // Concatenate the incoming list items with
                        // the existing list items.
                        merge(existing = [], incoming) {
                            return [...existing, ...incoming];
                        },
                    }
                }
            }
        }
    })
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApolloProvider client={client}>    
                <ScrollToTop/>
                <App />
            </ApolloProvider>
        </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
