import { useEffect } from 'react';
import axios from 'axios';
import CONSTANTS from '../Constants';
import PageTransition from '../Components/PageTransition/PageTransition';
import { useParams, useHistory } from 'react-router';
import { es } from 'date-fns/locale';

export default function AuthCallback({ isSafari }) {
    const { provider } = useParams();

    const history = useHistory();

    useEffect(() => {
        if (isSafari === true || isSafari === false) {
            const index = document.location.href.indexOf('?');
            const string = document.location.href.substring(index);
            axios({
                method: 'GET',
                url: `${CONSTANTS.baseUrl}/auth/${provider}/callback${string}`
            }).then(res => {
                if (res.status !== 200) {
                    throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
                }
                return res;
            })
                // .then(res => res.json())
                .then(res => {
                    localStorage.setItem('jwt', JSON.stringify(res.data.jwt));
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    if (isSafari) {
                        history.push(`/panther-pack${(res.data.user.socialMediaHandle && res.data.user.socialMedia) ? '?login=true' : ''}`)
                    } else {
                        window.close();
                    }
                }).catch(err => {
                    console.log(err)
                });
        }

        return () => {
            if (isSafari === true) {
                setTimeout(() => {
                    window.location.reload();
                }, 30);
            }
        }
    }, [isSafari]);
    return <PageTransition />
}