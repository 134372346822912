import { CircularProgress, makeStyles } from "@material-ui/core";

const SIZE_VALUES = [
    'small',
    'medium',
    'large'
];

const useStyles = makeStyles((theme) => ({
    colorPrimary: {
        color: '$primaryColor',
    },
    colorSecondary: {
        color: '$secondaryColor'
    }
  }));

export default function Loading({size, color}){
    const classes = useStyles();

    return(
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {(size === 'small')? <CircularProgress 
                size={'1rem'} 
                color={color}
                classes={{
                    colorPrimary: classes.colorPrimary, 
                    colorSecondary: classes.colorSecondary
                }}/>: null
            }
            {(size === 'medium')? <CircularProgress 
                size={'2rem'} 
                color={color}
                classes={{
                    colorPrimary: classes.colorPrimary, 
                    colorSecondary: classes.colorSecondary
                }}/>: null
            }
            {(size === 'large')? <CircularProgress 
                size={'3rem'} 
                color={color}
                classes={{
                    colorPrimary: classes.colorPrimary, 
                    colorSecondary: classes.colorSecondary
                }}/>: null
            }
        </div>
    )
}