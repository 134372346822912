import { useState, useEffect, useRef } from "react";
import "./MatchPage.scss";
import { ReactComponent as Calendar } from "../../../Assets/Icons/calendar.svg";
import { ReactComponent as Stadium } from "../../../Assets/Icons/stadium.svg";
import Panther from "../../../Assets/pink-panther-logo.png";
import MatchStats from "../../../Components/MatchStats/MatchStats";
import PageTransition from "../../../Components/PageTransition/PageTransition";
import { useSpring, a } from "react-spring";
import { useQuery, gql } from "@apollo/client";
import { useParams, useHistory } from "react-router";
import { format } from "date-fns";
import CONSTANTS from "../../../Constants";
import { reverseMatchesIdSeason11 } from "../../../utils/seasonConstants";

const MATCH_PAGE_QUERY = gql`
  query MatchesPageQuery($id: ID!) {
    match(id: $id) {
      result
      team {
        id
      }
    }
  }
`;

const PAST_MATCH_QUERY = gql`
  query PastMatchQuery($id: ID!) {
    match(id: $id) {
      venue {
        name
      }
      startTime
      jppScore
      opponentScore
      team {
        name
        icon {
          url
          alternativeText
        }
      }
      raidPoints {
        jpp
        opponent
      }
      tacklePoints {
        jpp
        opponent
      }
      allOutPoints {
        jpp
        opponent
      }
      extraPoints {
        jpp
        opponent
      }
      topPerformers {
        topRaider {
          player {
            firstName
            lastName
            halfImage {
              url
              alternativeText
            }
          }
          raidPoints
          raidsAttempted
        }
        topDefender {
          player {
            firstName
            lastName
            halfImage {
              url
              alternativeText
            }
          }
          tacklePoints
          tacklesAttempted
        }
      }
    }
  }
`;

const UPCOMING_MATCH_QUERY = gql`
  query UpcomingMatch($id: ID!, $teamId: ID) {
    match(id: $id) {
      team {
        name
        icon {
          url
          alternativeText
        }
      }
      venue {
        name
      }
      raidPoints {
        jpp
        opponent
      }
      tacklePoints {
        jpp
        opponent
      }
      allOutPoints {
        jpp
        opponent
      }
      extraPoints {
        jpp
        opponent
      }
      startTime
      watchOut {
        attack {
          firstName
          lastName
          halfImage {
            url
            alternativeText
          }
          totalRaids
          totalRaidPoints
        }
        defense {
          firstName
          lastName
          halfImage {
            url
            alternativeText
          }
          totalTackles
          totalTacklePoints
        }
        allRound {
          firstName
          lastName
          halfImage {
            url
            alternativeText
          }
          totalRaids
          totalRaidPoints
          totalTackles
          totalTacklePoints
        }
      }
    }
    wins: matchesConnection(where: { team: { id: $teamId }, result: "Win" }) {
      aggregate {
        count
      }
    }
    losses: matchesConnection(
      where: { team: { id: $teamId }, result: "Loss" }
    ) {
      aggregate {
        count
      }
    }
  }
`;

// QUERY WITH FORMS AS IT WAS USED PREVIOUSLY
// const UPCOMING_MATCH_QUERY = gql`
// query UpcomingMatch($id: ID!, $time: DateTime!){
//     match(id:$id){
//         team{
//             name,
//             icon{
//                 url,
//                 alternativeText
//             },
//             headToHeadSelf,
//             headToHeadOpponent
//         },
//         venue{
//             name
//         },
//         startTime,
//         watchOut{
//             attack{
//                 firstName,
//                 lastName,
//                 halfImage{
//                     url,
//                     alternativeText
//                 },
//                 totalRaids,
//                 totalRaidPoints
//             },
//             defense{
//                 firstName,
//                 lastName,
//                 halfImage{
//                     url,
//                     alternativeText
//                 },
//                 totalTackles,
//                 totalTacklePoints
//             },
//             allRound{
//                 firstName,
//                 lastName,
//                 halfImage{
//                     url,
//                     alternativeText
//                 },
//                 totalRaids,
//                 totalRaidPoints,
//                 totalTackles,
//                 totalTacklePoints
//             }
//         }
//     },
//     tournaments(sort:"startDate:desc", limit:2, where:{startDate_lte:$time}){
//         startDate,
//         teamStandings{
//             team{
//                 id,
//                 isPanther
//             },
//             form{
//                 matchResult
//             },
//             matches
//         }
//     }
// }`;

export default function MatchPage({ setProgress, now }) {
  const { id } = useParams();
  const history = useHistory();

  const { data, error, loading } = useQuery(MATCH_PAGE_QUERY, {
    variables: {
      id,
    },
  });

  if (data && data.match) {
    if (data.match.result === "TBD") {
      return (
        <UpcomingMatch
          teamId={data.match.team.id}
          matchId={id}
          setProgress={setProgress}
          now={now}
        />
      );
    } else return <PastMatch matchId={id} setProgress={setProgress} />;
  } else if (error || (data && !data.match)) {
    history.push("/matches");
    return <div />;
  } else return <PageTransition />;
}

export function PastMatch({ matchId, setProgress }) {
  const [animate, setAnimate] = useState(false); //Animates the top performers card when true
  const [stadium1, setStadium1] = useState(null);
  const [stadium2, setStadium2] = useState(null);
  const [matchStats, setMatchStats] = useState(null);
  const [players, setPlayers] = useState(null);

  console.log('Match Id = ', matchId)

  const { data, error, loading } = useQuery(PAST_MATCH_QUERY, {
    variables: {
      id: matchId,
    },
  });
  console.log(data);

  // const ReturnCircularSprings = (value, maxValue ) => {
  //     return useSpring({
  //         angle: animate? (value === maxValue? 6.28255 : (2 * Math.PI * value/maxValue)) : 0,
  //         number: animate? value : 0,
  //         config: {mass: 1, tension: 100, friction: 40, clamp: true
  //     }})
  // }

  // const playerSprings = PLAYERS.map(player =>
  //     player.data.map(item => ReturnCircularSprings(item.value, item.maxValue))
  // );

  const element = useRef();

  // useEffect(() => {
  //     const options = {
  //         root: null,
  //         rootMargin: '0px',
  //         threshold: 0.5
  //     };

  //     const callback = (entries) => {
  //         if(!animate && entries[0].isIntersecting){
  //             setAnimate(true);
  //         }
  //     }

  //     const observer = new IntersectionObserver(callback, options);
  //     observer.observe(element.current)
  // },[]);

  useEffect(() => {
    if (data) {
      var s = data.match.venue.name;
      if (s.includes(" ")) {
        var middle = Math.floor(s.length / 2);
        var before = s.lastIndexOf(" ", middle);
        var after = s.indexOf(" ", middle + 1);

        if (
          before === -1 ||
          (after !== -1 && middle - before >= after - middle)
        ) {
          middle = after;
        } else {
          middle = before;
        }

        var s1 = s.substr(0, middle);
        var s2 = s.substr(middle + 1);
        setStadium1(s1);
        setStadium2(s2);
      }
      const matchStats = [
        {
          name: "RAID POINTS",
          self: data.match.raidPoints.jpp,
          opponent: data.match.raidPoints.opponent,
          type: "stat",
        },
        {
          name: "TACKLE POINTS",
          self: data.match.tacklePoints.jpp,
          opponent: data.match.tacklePoints.opponent,
          type: "stat",
        },
        {
          name: "ALL OUT POINTS",
          self: data.match.allOutPoints.jpp,
          opponent: data.match.allOutPoints.opponent,
          type: "stat",
        },
        {
          name: "EXTRA POINTS",
          self: data.match.extraPoints.jpp,
          opponent: data.match.extraPoints.opponent,
          type: "stat",
        },
      ];
      const playerStats = [
        {
          position: "TOP RAIDER",
          name: `${data.match.topPerformers.topRaider.player.firstName} ${data.match.topPerformers.topRaider.player.lastName}`,
          image: data.match.topPerformers.topRaider.player.halfImage,
          data: [
            {
              label: "RAIDS ATTEMPTED",
              value: data.match.topPerformers.topRaider.raidsAttempted,
              maxValue: data.match.topPerformers.topRaider.raidsAttempted,
            },
            {
              label: "RAIDS POINTS",
              value: data.match.topPerformers.topRaider.raidPoints,
              maxValue: data.match.topPerformers.topRaider.raidPoints,
            },
          ],
        },
        {
          position: "TOP DEFENDER",
          name: `${data.match.topPerformers.topDefender.player.firstName} ${data.match.topPerformers.topDefender.player.lastName}`,
          image: data.match.topPerformers.topDefender.player.halfImage,
          data: [
            {
              label: "TACKLES ATTEMPTED",
              value: data.match.topPerformers.topDefender.tacklesAttempted,
              maxValue: data.match.topPerformers.topDefender.tacklesAttempted,
            },
            {
              label: "TACKLE POINTS",
              value: data.match.topPerformers.topDefender.tacklePoints,
              maxValue: data.match.topPerformers.topDefender.tacklePoints,
            },
          ],
        },
      ];
      setPlayers(playerStats);
      setMatchStats(matchStats);
      setProgress((prog) => prog + 60);
    }
  }, [data]);

  if (data) {
    return (
      <div className="match-page">
        <div className="section-1">
          <div className="venue">
            <Stadium />
            <p className="colored">
              {stadium1} <br /> {stadium2}
            </p>
          </div>
          {reverseMatchesIdSeason11.includes(matchId) ? <div className="scores">
            <div className="team">
              <img
                src={CONSTANTS.createUrl(data.match.team.icon.url)}
                alt={data.match.team.icon.alternativeText}
              />
              <p className="score">{data.match.opponentScore}</p>
              <p>{data.match.team.name}</p>
            </div>
            <p className="score">{`${data.match.opponentScore}-${data.match.jppScore}`}</p>
            <div className="team">
              <img src={Panther} alt="pink panther logo" />
              <p className="score">{data.match.jppScore}</p>
              <p>JAIPUR PINK PANTHERS</p>
            </div>
          </div> : <div className="scores">
            <div className="team">
              <img src={Panther} alt="pink panther logo" />
              <p className="score">{data.match.jppScore}</p>
              <p>JAIPUR PINK PANTHERS</p>
            </div>
            <p className="score">{`${data.match.jppScore}-${data.match.opponentScore}`}</p>
            <div className="team">
              <img
                src={CONSTANTS.createUrl(data.match.team.icon.url)}
                alt={data.match.team.icon.alternativeText}
              />
              <p className="score">{data.match.opponentScore}</p>
              <p>{data.match.team.name}</p>
            </div>
          </div>}
          <div className="time">
            <Calendar />
            <p className="colored">
              {format(
                new Date(data.match.startTime),
                "d LLLL, Y"
              ).toUpperCase()}{" "}
              <br />{" "}
              {`${format(new Date(data.match.startTime), "hh:mm aa")} IST`}
            </p>
          </div>
        </div>
        <div className="mobile-time-and-place">
          <div className="time">
            <Calendar />
            <p className="colored">
              {format(
                new Date(data.match.startTime),
                "d LLLL, Y"
              ).toUpperCase()}{" "}
              <br />{" "}
              {`${format(new Date(data.match.startTime), "hh:mm aa")} IST`}
            </p>
          </div>
          <div className="venue">
            <Stadium />
            <p className="colored">
              {stadium1} <br /> {stadium2}
            </p>
          </div>
        </div>
        <div className="section-2">
          <div className="stats">
            {matchStats && matchStats.map((item) => <MatchStats data={item} reverse={reverseMatchesIdSeason11.includes(matchId)} />)}
          </div>

          <div className="top-performers" ref={element}>
            <h4>TOP PERFORMERS OF THE MATCH</h4>
            <div className="main">
              {players &&
                players.map((item, playerIndex) => (
                  <div className="item">
                    <div className="imagebox">
                      <img
                        src={CONSTANTS.createUrl(item.image.url)}
                        alt={item.name}
                      />
                    </div>
                    <div className="info">
                      <div className="info-item">
                        <p className="label">{item.position}</p>
                        <p className="value">{item.name}</p>
                      </div>
                      {item.data.map((dataItem, dataIndex) => (
                        <div className="info-item">
                          <p className="label">{dataItem.label}</p>
                          <div className="circular-item">
                            <a.svg viewBox="0 0 100 100">
                              <circle cx="50" cy="50" fill="#3A4F99" r="50" />
                              <path
                                d={`M50,50 L50,100 A50 50 0 1 1 ${50 * (1 - Math.sin(6.28255))
                                  } ${50 * (1 + Math.cos(6.28255))} Z`}
                                fill="var(--primaryColor)"
                              />
                              <circle
                                cx="50"
                                cy="50"
                                fill="var(--secondaryColor)"
                                r="40"
                              />
                            </a.svg>
                            <p>{dataItem.value}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              <div className="divider" />
            </div>
          </div>
        </div>
      </div>
    );
  } else return <PageTransition />;
}

export function UpcomingMatch({ teamId, matchId, setProgress, now }) {
  // const now = useRef(new Date());
  const [animate, setAnimate] = useState(true); //Animates the top performers card when true
  const [stadium1, setStadium1] = useState(null);
  const [stadium2, setStadium2] = useState(null);
  const [matchStats, setMatchStats] = useState(null);
  const [players, setPlayers] = useState(null);

  const { data, loading, error } = useQuery(UPCOMING_MATCH_QUERY, {
    variables: {
      // time: now.current.toISOString(),
      id: matchId,
      teamId: teamId,
    },
  });
  console.log(data);

  // const ReturnCircularSprings = (value, maxValue ) => {
  //     return useSpring({
  //         angle: animate? (value === maxValue? 6.28255 : (2 * Math.PI * value/maxValue)) : 0,
  //         number: animate? value : 0,
  //         config: {mass: 1, tension: 100, friction: 40, clamp: true
  //     }})
  // }

  // const playerSprings = UPCOMING_PLAYERS.map(player =>
  //     player.data.map(item => ReturnCircularSprings(item.value, item.maxValue))
  // );

  const element = useRef();

  // useEffect(() => {
  //     const options = {
  //         root: null,
  //         rootMargin: '0px',
  //         threshold: 0.3
  //     };

  //     const callback = (entries) => {
  //         if(!animate && entries[0].isIntersecting){
  //             setAnimate(true);
  //         }
  //     }

  //     const observer = new IntersectionObserver(callback, options);
  //     observer.observe(element.current)
  // },[]);

  useEffect(() => {
    if (data) {
      var s = data.match.venue.name;
      if (s.includes(" ")) {
        var middle = Math.floor(s.length / 2);
        var before = s.lastIndexOf(" ", middle);
        var after = s.indexOf(" ", middle + 1);

        if (
          before === -1 ||
          (after !== -1 && middle - before >= after - middle)
        ) {
          middle = after;
        } else {
          middle = before;
        }

        var s1 = s.substr(0, middle);
        var s2 = s.substr(middle + 1);
        setStadium1(s1);
        setStadium2(s2);
      }
      const matchStats = [
        {
          name: "HEAD TO HEAD",
          self: data.wins.aggregate.count,
          opponent: data.losses.aggregate.count,
          type: "stat",
        },
        //    *{
        //      name: "RAID POINTS",
        //      self: data.match.raidPoints.jpp,
        //      opponent: data.match.raidPoints.opponent,
        //      type: "stat",
        //    },
        //    {
        //      name: "TACKLE POINTS",
        //      self: data.match.tacklePoints.jpp,
        //      opponent: data.match.tacklePoints.opponent,
        //      type: "stat",
        //    },
        //    {
        //      name: "ALL OUT POINTS",
        //      self: data.match.allOutPoints.jpp,
        //      opponent: data.match.allOutPoints.opponent,
        //      type: "stat",
        //    },
        //    {
        //      name: "EXTRA POINTS",
        //      self: data.match.extraPoints.jpp,
        //      opponent: data.match.extraPoints.opponent,
        //      type: "stat",
        //    },*/}
        // {
        //     name: 'RECENT FORM',
        //     self: data.tournaments.filter(tournament => {
        //             const standing = tournament.teamStandings.filter(standing => standing.team.isPanther)[0];
        //             return standing && standing.matches
        //         })[0].teamStandings.filter(standing =>
        //             standing.team.isPanther
        //         )[0].form.map(item => item.matchResult.toLowerCase()).splice(-5),
        //     opponent: data.tournaments.filter(tournament => {
        //             const standing = tournament.teamStandings.filter(standing => standing.team.id === teamId)[0];
        //             return standing && standing.matches
        //         })[0].teamStandings.filter(standing =>
        //             standing.team.id === teamId
        //         )[0].form.map(item => item.matchResult.toLowerCase()).splice(-5),
        //     type: 'form'
        // }
      ];
      const players = [
        data.match.watchOut.attack
          ? {
            position: "ATTACK",
            name: `${data.match.watchOut.attack.firstName} ${data.match.watchOut.attack.lastName}`,
            image: data.match.watchOut.attack.halfImage,
            data: [
              {
                label: "TOTAL RAIDS",
                value: data.match.watchOut.attack.totalRaids,
                maxValue: data.match.watchOut.attack.totalRaids,
              },
              {
                label: "RAID POINTS",
                value: data.match.watchOut.attack.totalRaidPoints,
                maxValue: data.match.watchOut.attack.totalRaidPoints,
              },
            ],
          }
          : null,
        data.match.watchOut.defense
          ? {
            position: "DEFENSE",
            name: `${data.match.watchOut.defense.firstName} ${data.match.watchOut.defense.lastName}`,
            image: data.match.watchOut.defense.halfImage,
            data: [
              {
                label: "TOTAL TACKLES",
                value: data.match.watchOut.defense.totalTackles,
                maxValue: data.match.watchOut.defense.totalTackles,
              },
              {
                label: "TACKLE POINTS",
                value: data.match.watchOut.defense.totalTacklePoints,
                maxValue: data.match.watchOut.defense.totalTacklePoints,
              },
            ],
          }
          : null,
        data.match.watchOut.allRound
          ? {
            position: "ALL ROUND",
            name: `${data.match.watchOut.allRound.firstName} ${data.match.watchOut.allRound.lastName}`,
            image: data.match.watchOut.allRound.halfImage,
            data: [
              {
                label: "TOTAL RAIDS",
                value: data.match.watchOut.allRound.totalRaids,
                maxValue: data.match.watchOut.allRound.totalRaids,
              },
              {
                label: "RAID POINTS",
                value: data.match.watchOut.allRound.totalRaidPoints,
                maxValue: data.match.watchOut.allRound.totalRaidPoints,
              },
              {
                label: "TOTAL TACKLES",
                value: data.match.watchOut.allRound.totalTackles,
                maxValue: data.match.watchOut.allRound.totalTackles,
              },
              {
                label: "TACKLE POINTS",
                value: data.match.watchOut.allRound.totalTacklePoints,
                maxValue: data.match.watchOut.allRound.totalTacklePoints,
              },
            ],
          }
          : null,
      ];
      const filteredPlayers = players.filter((item) => !!item);
      setPlayers(filteredPlayers);
      setMatchStats(matchStats);
      setProgress((prog) => prog + 60);
    }
  }, [data]);

  if (data) {
    return (
      <div className="match-page">
        <div className="section-1">
          <div className="venue">
            <Stadium />
            <p className="colored">
              {stadium1} <br /> {stadium2}
            </p>
          </div>
          <div className="scores">
            <div className="team">
              <img src={Panther} alt="pink panther logo" />
              <p>JAIPUR PINK PANTHERS</p>
            </div>
            <p className="score" />
            <div className="team">
              <img
                src={CONSTANTS.createUrl(data.match.team.icon.url)}
                alt={data.match.team.icon.alternativeText}
              />
              <p>{data.match.team.name}</p>
            </div>
          </div>
          <div className="time">
            <Calendar />
            <p className="colored">
              {format(
                new Date(data.match.startTime),
                "d LLLL, Y"
              ).toUpperCase()}{" "}
              <br />{" "}
              {`${format(new Date(data.match.startTime), "hh:mm aa")} IST`}
            </p>
          </div>
        </div>
        <div className="mobile-time-and-place">
          <div className="time">
            <Calendar />
            <p className="colored">
              {format(
                new Date(data.match.startTime),
                "d LLLL, Y"
              ).toUpperCase()}{" "}
              <br />{" "}
              {`${format(new Date(data.match.startTime), "hh:mm aa")} IST`}
            </p>
          </div>
          <div className="venue">
            <Stadium />
            <p className="colored">
              {stadium1} <br /> {stadium2}
            </p>
          </div>
        </div>
        <div className="section-2">
          <div className="stats">
            {matchStats && matchStats.map((item) => <MatchStats data={item} />)}
          </div>
        </div>
      </div>
    );
  } else return <PageTransition />;
}
