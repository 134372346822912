import {useRef, useEffect, useState} from 'react';
import './Merchandise.scss';
import CarouselImage from '../../Assets/MerchandisePage/banner.png';
import {ReactComponent as Cross} from '../../Assets/Icons/cross.svg';
import Merch1 from '../../Assets/Shopping/Merch1.png';
import Merch2 from '../../Assets/Shopping/Merch2.png';
import Merch3 from '../../Assets/Shopping/Merch3.png';
import PageTransition from '../../Components/PageTransition/PageTransition';
import CONSTANTS from '../../Constants';
import {useTransition, a, config} from 'react-spring';
import { useQuery, gql } from '@apollo/client';

const MERCH_QUERY = gql`
query MerchQuery{
    merchPage{
        carousel{
            url,
            alternativeText
        },
        miniBanner,
    },
    merchandises{
        name,
        image{
            url,
            alternativeText
        },
        subText,
        price,
        extra,
        url
    }
}`;

const CAROUSEL_DATA = [
    {img: CarouselImage, index: 0},
    {img: CarouselImage, index: 1},
    {img: CarouselImage, index: 2},
    {img: CarouselImage, index: 3},
    {img: CarouselImage, index: 4},
];

const PRODUCTS_DATA = [
    {img: Merch1, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
    {img: Merch2, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
    {img: Merch3, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
    {img: Merch1, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
    {img: Merch1, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
    {img: Merch2, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
    {img: Merch3, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
    {img: Merch1, tag: 'Newly launched', name: 'Lorem Ipsum Merch', subtext: '4 Colours', price: '₹ 899'},
]

const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export default function Merchandise({setProgress}){
    const {data, error, loading} = useQuery(MERCH_QUERY);
    const [carouselData, setCarouselData] = useState(null);
    const [productsData, setProductsData] = useState(null);
    console.log(data);
    console.log(error);
    console.log(loading);

    useEffect(() => {
        if(data){
            const carouselData = data.merchPage.carousel.map((item, index) => {
                return {img: item, index: index}
            });
            setCarouselData(carouselData);
            const productsData = data.merchandises.map(item => {
                return {
                    img: item.image,
                    tag: item.extra,
                    name: item.name,
                    subText: item.subText, 
                    price: `₹ ${item.price}`,
                    url: item.url
                }
            });
            setProductsData(productsData);
            setProgress(prog => prog + 60);
        }
    }, [data]);

    if(productsData && carouselData){
        return(
            <MerchandiseInner carouselData={carouselData} productsData={productsData} bannerText={data.merchPage.miniBanner}/>
        )
    }else return <PageTransition />

}


function MerchandiseInner({carouselData, productsData, bannerText}){
    const [bannerVisible, setBannerVisible] = useState(true);
    const [carouselIndex, setCarouselIndex] = useState(carouselData && carouselData[0].index);
    const previousCarouselIndex = usePrevious(carouselIndex);

    const timer = useRef();

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => setCarouselIndex(index => {
            if(index === carouselData.length - 1){
                return 0;
            }
            return index + 1;
        }), 8000);
        return () => {
            clearTimeout(timer.current);
        }
    }, [carouselIndex]);

    const transitions = useTransition(carouselData, {
        from: (item, index) => {
                if(carouselIndex === 0){
                    if (index === carouselIndex){
                        return {opacity: 1, transform: 'scale(1)', left: '0vw'}
                    } else if(index === carouselData.length - 1){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
                    } else if(index === 1){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
                    } else if (index < carouselIndex){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
                    } else if (index > carouselIndex){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
                    }
                } else if(carouselIndex === carouselData.length - 1){
                    if (index === carouselIndex){
                        return {opacity: 1, transform: 'scale(1)', left: '0vw'}
                    } else if(index === carouselData.length - 2){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
                    } else if(index === 0){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
                    } else if (index < carouselIndex){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
                    } else if (index > carouselIndex){
                        return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
                    }
                } else if(index === carouselIndex){
                    return {opacity: 1, transform: 'scale(1)', left: '0vw'}
                } else if (index < carouselIndex){
                    return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
                } else if (index > carouselIndex){
                    return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
                }
        },
        update: (item, index) => {
            if (carouselIndex === 0){
                if (index === carouselIndex){
                    return {opacity: 1, transform: 'scale(1)', left: '0vw'}
                } else if (index === carouselData.length - 1 && index === previousCarouselIndex){
                    return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
                } else if (index === 1 && index === previousCarouselIndex){
                    return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
                } else if (index === carouselData.length - 1){
                    return {opacity: 0, transform: 'scale(0.8)', left: '-100vw', config: {duration: 10}}
                } else if (index === 1){
                    return {opacity: 0, transform: 'scale(0.8)', left: '100vw', config: {duration: 10}}
                } else if (index > carouselIndex && index === previousCarouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '100vw'}
                } else if (index < carouselIndex && index === previousCarouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '-100vw'}
                }else if (index > carouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '100vw', config: {duration: 10}}
                } else if (index < carouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '-100vw', config: {duration: 10}}
                }
            }else if (carouselIndex === carouselData.length - 1){
                if (index === carouselIndex){
                    return {opacity: 1, transform: 'scale(1)', left: '0vw'}
                } else if (index === carouselData.length - 2 && index === previousCarouselIndex){
                    return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
                } else if (index === 0 && index === previousCarouselIndex){
                    return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
                } else if (index === carouselData.length - 2){
                    return {opacity: 0, transform: 'scale(0.8)', left: '-100vw', config: {duration: 10}}
                } else if (index === 0){
                    return {opacity: 0, transform: 'scale(0.8)', left: '100vw', config: {duration: 10}}
                } else if (index > carouselIndex && index === previousCarouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '100vw'}
                } else if (index < carouselIndex && index === previousCarouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '-100vw'}
                }else if (index > carouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '100vw', config: {duration: 10}}
                } else if (index < carouselIndex){
                    return {opacity: 0, transform: 'scale(0.8)', left: '-100vw', config: {duration: 10}}
                }
            }else if(index === carouselIndex){
                return {opacity: 1, transform: 'scale(1)', left: '0vw'}
            } else if (index === (carouselIndex - 1) && index === previousCarouselIndex){
                return {opacity: 0.6, transform: 'scale(0.8)', left: '-100vw'}
            } else if (index === (carouselIndex + 1) && index === previousCarouselIndex){
                return {opacity: 0.6, transform: 'scale(0.8)', left: '100vw'}
            } else if (index === (carouselIndex - 1)){
                return {opacity: 0, transform: 'scale(0.8)', left: '-100vw', config: {duration: 10}}
            } else if (index === (carouselIndex + 1)){
                return {opacity: 0, transform: 'scale(0.8)', left: '100vw', config: {duration: 10}}
            } else if (index > carouselIndex && index === previousCarouselIndex){
                return {opacity: 0, transform: 'scale(0.8)', left: '100vw'}
            } else if (index < carouselIndex && index === previousCarouselIndex){
                return {opacity: 0, transform: 'scale(0.8)', left: '-100vw'}
            } else if (index > carouselIndex){
                return {opacity: 0, transform: 'scale(0.8)', left: '100vw', config: {duration: 10}}
            } else if (index < carouselIndex){
                return {opacity: 0, transform: 'scale(0.8)', left: '-100vw', config: {duration: 10}}
            }
        },
        config: config.slow,
    });

    // RETURNS TRUE IF CAROUSEL SLIDES TOWARDS RIGHT
    const carouselDirection = () => {
        if(carouselIndex === 0){
            if(previousCarouselIndex === carouselData.length - 1){
                return false;
            }else return true;
        }else if(carouselIndex === carouselData.length - 1){
            if(previousCarouselIndex === 0){
                return true;
            }else return false;
        }else if(previousCarouselIndex < carouselIndex) {
            return false
        }
        return true
    }

    return(
        <div className="merchandise-page">
            {(bannerText)?
                <p 
                    className={`banner-text ${bannerVisible? '' : 'invisible'}`}
                >{bannerText} <Cross onClick={() => setBannerVisible(false)}/></p> 
                : null
            }
            <div className="carousel">
                <div className="main">
                    {transitions((styles, item) => 
                        <a.img 
                            src={CONSTANTS.createUrl(item.img.url)} 
                            key={item.index} 
                            alt={item.img.alternativeText} 
                            style={styles}
                            className={`${(previousCarouselIndex === item.index ? 
                                ((carouselDirection())? 'shrink-left' : 'shrink-right') : '')}`}
                        />)
                    }
                </div>
                <div className="controls">
                    {carouselData.map((item, index) => 
                        <button 
                            className={`carousel-indicator ${(carouselIndex === index)? 'active' : ''}`}
                            onClick={() =>  setCarouselIndex(index)}
                        />
                    )}
                </div>
            </div>
            <div className="products">
                {/* <div className="filters">
                    <div className="item">
                        <p>Sort By</p>
                        <img src={ArrowIcon} alt="" />
                    </div>
                </div> */}

                <div className="main">
                    {productsData.map(item =>     
                        <a className="item" href={item.url} target="_blank" rel="noreferrer">
                            <div className="imagebox">
                                <img src={CONSTANTS.createUrl(item.img.url)} alt={item.img.alternativeText} />
                            </div>
                            <div className="info">
                                <p className="tag">{item.tag}</p>
                                <p className="name">{item.name}</p>
                                <p className="sub-text">{item.subText}</p>
                                <p className="price">{item.price}</p>
                            </div>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}