import { useState, useEffect } from "react";
import "./Matches.scss";
import Dropdown from "../../Components/UI/Dropdown/Dropdown";
import PageTransition from "../../Components/PageTransition/PageTransition";
import MatchPage from "./MatchPage/MatchPage";
import { ReactComponent as Calendar } from "../../Assets/Icons/calendar.svg";
import { ReactComponent as Stadium } from "../../Assets/Icons/stadium.svg";
import { ReactComponent as Ticket } from "../../Assets/Icons/ticket.svg";
import { ReactComponent as Disney } from "../../Assets/disney-hotstar.svg";
import { ReactComponent as Field } from "../../Assets/Icons/field-vertical.svg";
import { ReactComponent as Graph } from "../../Assets/Icons/bar-graph.svg";
import Panther from "../../Assets/pink-panther-logo.png";
import {
  Link,
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import { format } from "date-fns";
import CONSTANTS from "../../Constants";
import { reverseMatchesIdSeason11 } from "../../utils/seasonConstants";


//Previous Match IDs
const PREVIOUS_MATCH_ID = {
  'Haryana Steelers': '204',
  'Gujarat Giants': '183',
  'U.P. Yoddhas': '187',
  'Patna Pirates': '189',
  'Tamil Thalaivas': '194',
  'Bengaluru Bulls': '192',
  'Bengal Warriorz': '196',
  'Puneri Paltan': '199',
  'Telugu Titans': '198',
  'Dabang Delhi K.C.': '190',
  'U Mumba': '201'
}


const MATCHES_QUERY = gql`
  query MatchesQuery($id: ID!) {
    tournament(id: $id) {
      id
      startDate
      name
      matches(sort: "startTime:asc") {
        startTime
        status
        result
        type
        venue {
          name
        }
        id
        team {
          icon {
            url
            alternativeText
          }
          name
        }
        jppScore
        opponentScore
        streamUrl
        ticketAvailable
      }
    }
  }
`;

const SEASONS_QUERY = gql`
  query SeasonsQuery {
    tournaments(sort: "startDate:desc") {
      name
      id
    }
  }
`;

const ALL_MATCHES_QUERY = gql`
  query AllMatchesQuery {
    tournaments(sort: "startDate:desc") {
      id
      startDate
      name
      matches(sort: "status:desc,startTime:asc") {
        startTime
        status
        result
        type
        venue {
          name
        }
        id
        team {
          icon {
            url
            alternativeText
          }
          name
        }
        jppScore
        opponentScore
        streamUrl
        ticketAvailable
      }
    }
  }
`;

export default function Matches({ setProgress }) {
  return (
    <Switch>
      <Route path="/matches" exact>
        <MatchesMain setProgress={setProgress} />
      </Route>
      <Route path="/matches?tournament=:id?">
        <MatchesMain setProgress={setProgress} />
      </Route>
      <Route path="/matches/:id">
        <MatchPage setProgress={setProgress} />
      </Route>
      <Redirect to="/matches" />
    </Switch>
  );
}

function MatchesMain({ setProgress }) {
  const [seasonValue, setSeasonValue] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const [matches, setMatches] = useState([]);
  const [seasonId, setSeasonId] = useState(null);


  const { data: seasonsData } = useQuery(SEASONS_QUERY);
  const [getMatches, { data, error, loading }] = useLazyQuery(MATCHES_QUERY, {
    variables: {
      id: parseInt(seasonId),
    },
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const selection = new URL(window.location.href).searchParams.get(
      "tournament"
    );
    if (seasonsData) {
      if (selection) {
        if (
          seasonsData.tournaments.findIndex((item) => item.id === selection) ===
          -1
        ) {
          history.replace(
            `/matches?tournament=${seasonsData.tournaments[0].id}`
          );
        } else {
          setSeasonId(
            new URL(window.location.href).searchParams.get("tournament")
          );
          const dropdownOptions = seasonsData.tournaments.map((item) => {
            return { text: item.name, value: parseInt(item.id) };
          });
          setSeasonValue(parseInt(selection));
          setDropdownOptions(dropdownOptions);
        }
      } else {
        history.replace(`/matches?tournament=${seasonsData.tournaments[0].id}`);
      }
    }
  }, [location.search, seasonsData]);

  useEffect(() => {
    if (seasonId) {
      getMatches();
    }
  }, [seasonId]);

  useEffect(() => {
    if (data) {
      // const dropdownOptions = seasonsData.tournaments.map(item => {
      //     return {text: item.name, value: parseInt(item.id)}
      // });
      // const selection = new URL(window.location.href).searchParams.get("tournament");
      // if(!selection){
      //     history.replace(`/matches?tournament=${dropdownOptions[0].value}`);
      //     setSeasonValue(dropdownOptions[0].value);
      // } else{
      //     setSeasonValue(parseInt(selection));
      // }
      // setDropdownOptions(dropdownOptions);
      setProgress((prog) => prog + 60);
      const matches = data.tournament.matches;
      console.log('MAtches = ', matches)
      const timeOrderedMatches = [...data.tournament.matches].sort(
        (a, b) => new Date(a.startTime) - new Date(b.startTime)
      );
      const newMatches = matches.map((item) => {
        const newItem = { ...item };
        newItem.matchNumber = (
          timeOrderedMatches.findIndex((x) => x.id === item.id) + 1
        )
          .toString()
          .padStart(2, "0");
        return newItem;
      });
      setMatches(newMatches);
    }
  }, [data]);

  console.log('MAtches = ', matches)
  useEffect(() => {
    if (seasonValue) {
      history.replace(`/matches?tournament=${seasonValue}`);
    }
  }, [seasonValue]);

  // useEffect(() => {
  //     if(data){
  //         const dropdownOptions = data.tournaments.map(item => {
  //             return {text: item.name, value: parseInt(item.id)}
  //         });
  //         const selection = new URL(window.location.href).searchParams.get("tournament");
  //         if(!selection){
  //             history.replace(`/matches?tournament=${dropdownOptions[0].value}`);
  //             setSeasonValue(dropdownOptions[0].value);
  //         } else{
  //             setSeasonValue(parseInt(selection));
  //         }
  //         setDropdownOptions(dropdownOptions);
  //         setProgress(prog => prog + 60);
  //     }
  // }, [data]);

  // useEffect(() => {
  //     if(data && (seasonValue!== null)){
  //         history.replace(`/matches?tournament=${seasonValue}`);
  //         const matches = data.tournaments.filter(item => parseInt(item.id) === seasonValue)[0].matches;
  //         const timeOrderedMatches = [...data.tournaments.filter(item => parseInt(item.id) === seasonValue)[0].matches].sort((a, b) => (new Date(a.startTime) - new Date(b.startTime)));
  //         const newMatches = matches.map(item => {
  //             const newItem = {...item};
  //             newItem.matchNumber = (timeOrderedMatches.findIndex(x => x.id === item.id) + 1).toString().padStart(2, '0');
  //             return newItem;
  //         })
  //         console.log(newMatches);
  //         setMatches(newMatches);
  //     }
  // }, [seasonValue, data]);

  if (data) {
    return (
      <div className="matches-page">
        <div className="section-1">
          <div className="row">
            <h1>MATCHES</h1>
            {dropdownOptions && seasonValue !== null && (
              <Dropdown
                options={dropdownOptions}
                value={seasonValue}
                setValue={setSeasonValue}
              />
            )}
          </div>
          <div className="tabs">
            <div className="item">FIXTURES & RESULTS</div>
          </div>
        </div>
        <div className="section-2">
          {matches.length
            ? matches.map((item, index) => {
              if (item.result === "TBD") {
                return (
                  <div className="item">
                    <div className="time-and-place">
                      <div className="item">
                        <Calendar />
                        <p>
                          {format(
                            new Date(item.startTime),
                            "d LLLL, Y"
                          ).toUpperCase()}{" "}
                          <br />{" "}
                          {`${format(
                            new Date(item.startTime),
                            "hh:mm aa"
                          )} IST`}
                        </p>
                      </div>
                      <div className="item">
                        <Stadium />
                        <p>{item.venue.name}</p>
                      </div>
                      {item.ticketAvailable ? (
                        <Link to="/tickets" className="blue">
                          <Ticket />
                          <p>BUY TICKET</p>
                        </Link>
                      ) : null}
                    </div>
                    <div className="card" to={`/matches/${item.id}`}>
                      <div className="main">
                        {item.type ? (
                          <div className="type">
                            {item.type === "league" ? <p>LEAGUE</p> : null}
                            {item.type === "knockout" ? (
                              <p>KNOCKOUT</p>
                            ) : null}
                            {item.type === "quarterfinal" ? (
                              <p>QUARTERFINALS</p>
                            ) : null}
                            {item.type === "semifinal" ? (
                              <p>SEMIFINALS</p>
                            ) : null}
                            {item.type === "final" ? <p>FINAL</p> : null}
                          </div>
                        ) : null}
                        {reverseMatchesIdSeason11.includes(item.id) ? <div className="scores">
                          <div className="item">
                            <img
                              src={CONSTANTS.createUrl(item.team.icon.url)}
                              alt={item.team.icon.alternativeText}
                            />
                            <p className="name">{item.team.name}</p>
                          </div>
                          <div className="item">
                            <img
                              src={Panther}
                              alt="jaipur pink panther logo"
                            />
                            <p className="name">JAIPUR PINK PANTHERS</p>
                          </div>
                        </div> : <div className="scores">
                          <div className="item">
                            <img
                              src={Panther}
                              alt="jaipur pink panther logo"
                            />
                            <p className="name">JAIPUR PINK PANTHERS</p>
                          </div>
                          <div className="item">
                            <img
                              src={CONSTANTS.createUrl(item.team.icon.url)}
                              alt={item.team.icon.alternativeText}
                            />
                            <p className="name">{item.team.name}</p>
                          </div>
                        </div>}
                        <div className="time-and-place">
                          <div className="item">
                            <Calendar />
                            <p>
                              {format(
                                new Date(item.startTime),
                                "d LLLL, Y"
                              ).toUpperCase()}{" "}
                              <br />{" "}
                              {`${format(
                                new Date(item.startTime),
                                "hh:mm aa"
                              )} IST`}
                            </p>
                          </div>
                          <div className="item">
                            <Stadium />
                            <p>{item.venue.name}</p>
                          </div>
                        </div>
                        <div className="links">
                          {/* Changed link of previous Match Here */}
                          <Link to={`/matches/${PREVIOUS_MATCH_ID[item.team.name.trim()]}`} className="blue">
                            <Graph />
                            <p>PREVIOUS STATS</p>
                          </Link>
                          {item.ticketAvailable ? (
                            <Link to="/tickets" className="blue">
                              <Ticket />
                              <p>BUY TICKET</p>
                            </Link>
                          ) : null}
                        </div>
                      </div>
                      <div className="title">
                        <h2>{`MATCH ${item.matchNumber}`}</h2>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="item">
                    <div className="time-and-place">
                      <div className="item">
                        <Calendar />
                        <p>
                          {format(
                            new Date(item.startTime),
                            "d LLLL, Y"
                          ).toUpperCase()}{" "}
                          <br />{" "}
                          {`${format(
                            new Date(item.startTime),
                            "hh:mm aa"
                          )} IST`}
                        </p>
                      </div>
                      <div className="item">
                        <Stadium />
                        <p>{item.venue.name}</p>
                      </div>
                    </div>
                    <div className="card">
                      <div className="main">
                        {item.type ? (
                          <div className="type">
                            {item.type === "league" ? <p>LEAGUE</p> : null}
                            {item.type === "knockout" ? (
                              <p>KNOCKOUT</p>
                            ) : null}
                            {item.type === "quarterfinal" ? (
                              <p>QUARTERFINALS</p>
                            ) : null}
                            {item.type === "semifinal" ? (
                              <p>SEMIFINALS</p>
                            ) : null}
                            {item.type === "final" ? <p>FINAL</p> : null}
                          </div>
                        ) : null}
                        {reverseMatchesIdSeason11.includes(item.id) ? <div className="scores">
                          <div className="item">
                            <img
                              src={CONSTANTS.createUrl(item.team.icon.url)}
                              alt={item.team.icon.alternativeText}
                              className="small"
                            />
                            <p className="score">{item.opponentScore}</p>
                            <p className="name">{item.team.name}</p>
                          </div>
                          <div className="item">
                            <img
                              src={Panther}
                              alt="jaipur pink panther logo"
                              className="small"
                            />
                            <p className="score">{item.jppScore}</p>
                            <p className="name">JAIPUR PINK PANTHERS</p>
                          </div>
                        </div> : <div className="scores">
                          <div className="item">
                            <img
                              src={Panther}
                              alt="jaipur pink panther logo"
                              className="small"
                            />
                            <p className="score">{item.jppScore}</p>
                            <p className="name">JAIPUR PINK PANTHERS</p>
                          </div>
                          <div className="item">
                            <img
                              src={CONSTANTS.createUrl(item.team.icon.url)}
                              alt={item.team.icon.alternativeText}
                              className="small"
                            />
                            <p className="score">{item.opponentScore}</p>
                            <p className="name">{item.team.name}</p>
                          </div>
                        </div>}
                        <div className="time-and-place">
                          <div className="item">
                            <Calendar />
                            <p>
                              {format(
                                new Date(item.startTime),
                                "d LLLL, Y"
                              ).toUpperCase()}{" "}
                              <br />{" "}
                              {`${format(
                                new Date(item.startTime),
                                "hh:mm aa"
                              )} IST`}
                            </p>
                          </div>
                          <div className="item">
                            <Stadium />
                            <p>{item.venue.name}</p>
                          </div>
                        </div>
                        <div className="links">
                          <Link className="pink" to={`/matches/${item.id}`}>
                            <Field />
                            <p>MATCH HUB</p>
                          </Link>
                          {item.streamUrl ? (
                            <a
                              className="blue"
                              href={item.streamUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Disney />
                              <p>HIGHLIGHTS</p>
                            </a>
                          ) : null}
                        </div>
                      </div>
                      <div className="title">
                        <h2>{`MATCH ${item.matchNumber}`}</h2>
                      </div>
                    </div>
                  </div>
                );
              }
            })
            : null}
        </div>
      </div>
    );
  } else return <PageTransition />;
}
