import { useEffect } from 'react';
import './PageTransition.scss';
import { CircularProgress, makeStyles } from "@material-ui/core";
import Panther from '../../Assets/pink-panther-logo.png';

const useStyles = makeStyles((theme) => ({
    colorPrimary: {
        color: 'var(--primaryColor)',
    },
    colorSecondary: {
        color: 'var(--secondaryColor)'
    }
  }));

export default function PageTransition(){

    const classes = useStyles();

    useEffect(() => {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        document.getElementsByTagName('body')[0].style.height = window.innerHeight + "px";

        return () => {
            document.getElementsByTagName('body')[0].style.overflow = 'initial'
            document.getElementsByTagName('body')[0].style.height = 'auto';
            // window.scrollTo(0, 0);
        }
    },[]);

    return(
        <div className="page-transition">
            <CircularProgress 
                size={'15rem'} 
                color="secondary"
                classes={{
                    colorPrimary: classes.colorPrimary, 
                    colorSecondary: classes.colorSecondary
                }}
            />
            <img 
                src={Panther} 
                alt="" 
                style={{
                    height: '5rem',
                    width: 'auto',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            />
        </div>
    )
}