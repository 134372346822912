export function calculateAge(dob) {
    const birthDate = new Date(dob); // Convert dob string to Date object
    const today = new Date(); // Get the current date

    let age = today.getFullYear() - birthDate.getFullYear(); // Calculate initial age based on year difference
    const monthDifference = today.getMonth() - birthDate.getMonth(); // Calculate month difference

    // Adjust age if the current month is before the birth month, or if it's the birth month but the day hasn't passed yet
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}
