import { useState, useEffect, useRef } from 'react';
import './GalleryModal.scss'
import { Dialog, makeStyles } from '@material-ui/core';
import Cross from '../../../Assets/Icons/cross.svg';
import {ReactComponent as Arrow} from '../../../Assets/Icons/arrow-right.svg';
import { useSpring, a } from 'react-spring';
import CONSTANTS from '../../../Constants';

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        backgroundColor: 'transparent',
    },
    backdropRoot: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
    }
}));

export default function GaleryModal({open, setOpen, data, selectedIndex, setSelectedIndex, type}){
    const classes = useStyles();
    const [scrollPos, setScrollPos] = useState(0);

    const row = useRef(null);

    const {scroll} = useSpring({scroll: scrollPos});

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(row.current){
            const child = row.current.children[selectedIndex];
            const offsetWidth = row.current.offsetWidth;
            const margin = parseInt(window.getComputedStyle(row.current, null).getPropertyValue('margin-right'));
            const padding = parseInt(window.getComputedStyle(row.current, null).getPropertyValue('padding-right'));
            const fraction = (selectedIndex)/(data.length - 1);
            setScrollPos(child.offsetLeft - padding - fraction * (offsetWidth - 2 * padding - child.clientWidth));
        }
    }, [selectedIndex, data.length])
    
    return(
        <Dialog
            open={open}
            onClose={handleClose}
            BackdropProps={{classes: {root: classes.backdropRoot}}}
            PaperProps={{classes: {root: classes.paperRoot}}}
            fullScreen
        >
            <div className="dialog-content">
                <button 
                    className="close-button"
                    onClick={handleClose}
                >
                    <img src={Cross} alt="" />
                </button>
                <div className="main">
                    <button 
                        onClick={() => {
                            if(selectedIndex === 0){
                                return
                            }
                            setSelectedIndex(index => index - 1)
                        }}
                        disabled={selectedIndex === 0}
                    >
                        <div className="button">
                            <Arrow/>
                        </div>
                    </button>
                    {(type === 'image')? <img src={CONSTANTS.createUrl(data[selectedIndex].url)} alt={data[selectedIndex].alternativeText} /> : null }
                    {(type === 'video')? 
                        <iframe 
                            src={data[selectedIndex].videoUrl} 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                            allowFullScreen
                        ></iframe>
                        : null 
                    }
                    
                    <button
                        onClick={() => {
                            if(selectedIndex === data.length - 1){
                                return
                            }
                            setSelectedIndex(index => index + 1)
                        }}
                        disabled={selectedIndex === data.length - 1}
                    >
                        <div className="button">
                            <Arrow/>
                        </div>
                    </button>

                    <div className="mobile-controls">
                        <button 
                            onClick={() => {
                                if(selectedIndex === 0){
                                    return
                                }
                                setSelectedIndex(index => index - 1)
                            }}
                            disabled={selectedIndex === 0}
                        >
                            <Arrow/>
                        </button>
                        <button
                            onClick={() => {
                                if(selectedIndex === data.length - 1){
                                    return
                                }
                                setSelectedIndex(index => index + 1)
                            }}
                            disabled={selectedIndex === data.length - 1}
                        >
                            <Arrow/>
                        </button>
                    </div>
                </div>
                <a.div className="row" scrollLeft={scroll} ref={row}>
                    {data.map((item, index) => 
                        <img 
                            src={(type === 'image')? CONSTANTS.createUrl(item.url) : `https://img.youtube.com/vi/${item.videoUrl.substring(item.videoUrl.indexOf('embed/') + 6)}/0.jpg`} 
                            alt=""
                            className={(index === selectedIndex)? 'selected' : ''}
                            onClick={() => setSelectedIndex(index)}
                        />
                    )}
                </a.div>
            </div>
        </Dialog>
    )
}