import { useState, useEffect, useRef } from 'react';
import './Player.scss';
import { ReactComponent as Instagram } from '../../../Assets/Icons/instagram.svg';
import { ReactComponent as Facebook } from '../../../Assets/Icons/facebook.svg';
import { ReactComponent as Twitter } from '../../../Assets/Icons/twitter.svg';
import { ReactComponent as Shirt } from '../../../Assets/Icons/shirt.svg';
import { ReactComponent as Field } from '../../../Assets/Squad/field.svg';
import Arrow from '../../../Assets/Icons/arrow-right.svg';
import GradientImage from '../../../Components/UI/GradientImage/GradientImage';
import PlayerStats from '../../../Components/PlayerStats/PlayerStats';
import PageTransition from '../../../Components/PageTransition/PageTransition';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useSpring, a } from 'react-spring';
import { enableScroll, disableScroll } from '../../../ModifyScroll';
import CONSTANTS from '../../../Constants';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { calculateAge } from '../../../utils/misc';

const PLAYER_QUERY = gql`
query PlayerQuery($number: Int!){
    player: players(where:{id:$number, isActive: true}){
        id,
        squadNumber,
        firstName,
        lastName,
        halfImage{
            url,
            alternativeText
        },
        fullImage{
            url,
            alternativeText
        },
        merchLink,
        instagram,
        facebook,
        twitter,
        category,
        position,
        country,
        dateOfBirth,
        aboutText{
            value
        }
        matchesPlayed,
        notOutPercentage,
        totalPoints,
        mostPointsInMatch,
        totalRaids,
        raidSuccessPercentage,
        totalRaidPoints,
        superTens,
        superRaidsNumber,
        totalTackles,
        strikeRate,
        totalTacklePoints,
        highFives,
        superTackles,
    },
    allPlayers: players(where: {isActive:true}){
        id,
        category,
        position,
        halfImage{
            url,
            alternativeText
        },
        firstName,
        lastName,
        squadNumber,
        matchesPlayed,
        totalPoints,
        mostPointsInMatch,
        raidSuccessPercentage,
        totalRaidPoints,
        superTens,
        superRaidsNumber,
        strikeRate,
        totalTacklePoints,
        highFives,
        superTackles,
    }
}`;

export default function Player({ setProgress }) {

    const [loaded, setLoaded] = useState(false);
    const [lineDim, setLineDim] = useState(null);
    const [player, setPlayer] = useState(null);
    const [overallStats, setOverallStats] = useState(null);
    const [attackingStats, setAttackingStats] = useState(null);
    const [defensiveStats, setDefensiveStats] = useState(null);
    const [otherPlayers, setOtherPlayers] = useState(null);
    const [otherPlayerScroll, setOtherPlayerScroll] = useState(0);
    const [largeScreen, setLargeScreen] = useState(0); // Boolean, when true, show desktop styles

    const { id } = useParams();
    const history = useHistory();

    const { data, loading, error } = useQuery(PLAYER_QUERY, {
        variables: {
            number: parseInt(id)
        }
    });
    console.log(data);

    const image = useRef();
    const jerseybox = useRef();

    const otherPlayerStyles = useSpring({ scroll: otherPlayerScroll });
    const otherPlayerRef = useRef();

    useEffect(() => {
        if (data) {
            setLoaded(true);
            setPlayer(data.player[0]);
            setOtherPlayers(data.allPlayers.filter(item => (item.category === data.player[0].category && item.id !== data.player[0].id)))
            setProgress(prog => prog + 60);
        }
    }, [data]);

    useEffect(() => {
        if (player) {
            const overallStats = {
                largeTitle: 'MATCHES PLAYED',
                largeValue: player.matchesPlayed,
                radialTitle: 'NOT OUT PERCENTAGE',
                radialValue: player.notOutPercentage,
                linear: [
                    { title: 'TOTAL POINTS EARNED', value: player.totalPoints, maxValue: Math.max(...data.allPlayers.map(item => item.totalPoints)) },
                    { title: 'MOST POINTS IN A MATCH', value: player.mostPointsInMatch, maxValue: Math.max(...data.allPlayers.map(item => item.mostPointsInMatch)) }
                ]
            };
            const attackingStats = {
                largeTitle: 'TOTAL RAIDS',
                largeValue: player.totalRaids,
                radialTitle: 'SUCCESSFUL RAIDS',
                radialValue: player.raidSuccessPercentage,
                linear: [
                    { title: 'TOTAL RAID POINTS', value: player.totalRaidPoints, maxValue: Math.max(...data.allPlayers.map(item => item.totalRaidPoints)) },
                    { title: 'SUPER 10S', value: player.superTens, maxValue: Math.max(...data.allPlayers.map(item => item.superTens)) },
                    { title: 'NO. OF SUPER RAIDS', value: player.superRaidsNumber, maxValue: Math.max(...data.allPlayers.map(item => item.superRaidsNumber)) },
                    { title: 'AVG RAID POINTS/MATCH', value: player.matchesPlayed ? player.totalRaidPoints / player.matchesPlayed : 0, maxValue: Math.max(...data.allPlayers.map(item => item.matchesPlayed ? (item.totalRaidPoints / item.matchesPlayed) : 0)) },
                ]
            };
            const defensiveStats = {
                largeTitle: 'TOTAL TACKLES',
                largeValue: player.totalTackles,
                radialTitle: 'TACKLE STRIKE RATE',
                radialValue: player.strikeRate,
                linear: [
                    { title: 'TOTAL TACKLE POINTS', value: player.totalTacklePoints, maxValue: Math.max(...data.allPlayers.map(item => item.totalTacklePoints)) },
                    { title: 'HIGH 5S', value: player.highFives, maxValue: Math.max(...data.allPlayers.map(item => item.highFives)) },
                    { title: 'NO. OF SUPER TACKLES', value: player.superTackles, maxValue: Math.max(...data.allPlayers.map(item => item.superTackles)) },
                    { title: 'AVG TACKLE POINTS/MATCH', value: player.matchesPlayed ? player.totalTacklePoints / player.matchesPlayed : 0, maxValue: Math.max(...data.allPlayers.map(item => item.matchesPlayed ? (item.totalTacklePoints / item.matchesPlayed) : 0)) },
                ]
            };
            setOverallStats(overallStats);
            setAttackingStats(attackingStats);
            setDefensiveStats(defensiveStats);
        }
    }, [player])

    useEffect(() => {
        disableScroll();
        const setSize = (event) => {
            setLargeScreen(event.target.innerWidth >= 500);
        }
        setLargeScreen(window.innerWidth >= 500);
        window.addEventListener('resize', setSize);
        return () => {
            window.removeEventListener('resize', setSize);
        }
    }, []);

    useEffect(() => {
        // disableScroll();
        const timeout = setTimeout(() => {
            if (jerseybox.current) {
                const imageDim = image.current.getBoundingClientRect();
                const jerseyDim = jerseybox.current.getBoundingClientRect();
                const top = imageDim.top + imageDim.height * 0.5;
                const left = jerseyDim.right;
                const width = imageDim.left - left;
                const height = jerseyDim.top - top;
                setLineDim({ left, width, top, height, transform: 'scale(1)', opacity: 1 });
            } else {
                enableScroll();
            }
        }, 100);

        const resizeHandler = () => {
            const imageDim = image.current.getBoundingClientRect();
            const jerseyDim = jerseybox.current.getBoundingClientRect();
            const top = imageDim.top + imageDim.height * 0.5;
            const left = jerseyDim.right;
            const width = imageDim.left - left;
            const height = jerseyDim.top - top;
            setLineDim({ left, width, top, height, transform: 'scale(1)', opacity: 1 });
        }

        if (jerseybox.current) {
            window.addEventListener('resize', resizeHandler);
        }

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('resize', resizeHandler);
        }
    }, [loaded]);

    useEffect(() => {
        enableScroll();
    }, [lineDim]);

    const otherPlayersScrollForward = () => {
        const innerWidth = otherPlayerRef.current.scrollWidth;
        const visibleWidth = otherPlayerRef.current.offsetWidth;
        if (otherPlayerRef.current) {
            const playerItemWidth = visibleWidth * 34 / 100;
            if ((otherPlayerScroll + visibleWidth + playerItemWidth * 1.5) > innerWidth) {
                setOtherPlayerScroll(innerWidth - visibleWidth);
            } else setOtherPlayerScroll(currScroll => currScroll + playerItemWidth);
        }
    }

    const otherPlayersScrollBack = () => {
        const visibleWidth = otherPlayerRef.current.offsetWidth;
        if (otherPlayerRef.current) {
            const playerItemWidth = visibleWidth * 34 / 100;
            if ((otherPlayerScroll - playerItemWidth * 1.5) < 0) {
                setOtherPlayerScroll(0)
            } else setOtherPlayerScroll(currScroll => currScroll - playerItemWidth)
        }
    };

    console.log(otherPlayerScroll);

    if (player) {
        return (
            <div className="player-page">
                <svg viewBox="0 0 50 50" style={lineDim || { width: 0, height: 0 }} preserveAspectRatio="none">
                    <path d="M50,0-0,50" stroke="white" strokeWidth="0.5px" />
                </svg>
                {largeScreen ?
                    <div className="section-1-desktop">
                        <h1>{`${player.firstName} ${player.lastName}`}</h1>
                        <div className="row">
                            <div className="group">
                                <div className="socials">
                                    <p>SOCIAL CHANNELS</p>
                                    <div className="links">
                                        {player.instagram && <a href={player.instagram} target="_blank" rel="noreferrer">
                                            <Instagram />
                                        </a>}
                                        {player.facebook && <a href={player.facebook} target="_blank" rel="noreferrer">
                                            <Facebook />
                                        </a>}
                                        {player.twitter && <a href={player.twitter} target="_blank" rel="noreferrer">
                                            <Twitter />
                                        </a>}
                                    </div>
                                </div>
                                {player.merchLink && <div className="jersey" ref={jerseybox}>
                                    <p>GET JERSEY</p>
                                    <div className="links">
                                        <a href={player.merchLink} target="_blank" rel="noreferrer">
                                            <Shirt />
                                        </a>
                                    </div>
                                </div>}
                            </div>
                            <div className="group">
                                <div className="imagebox" ref={largeScreen ? image : null}>
                                    <GradientImage image={CONSTANTS.createUrl(player.halfImage.url)} />
                                </div>
                                <p className="number">{player.squadNumber}</p>
                            </div>
                            <div className="group">
                                {(player.position === 'LEFTRAIDER') ? <Field className="left-raider" /> : null}
                                {(player.position === 'RIGHTRAIDER') ? <Field className="right-raider" /> : null}
                                {(player.position === 'LEFTCOVER') ? <Field className="left-cover" /> : null}
                                {(player.position === 'RIGHTCOVER') ? <Field className="right-cover" /> : null}
                                {(player.position === 'LEFTCORNER') ? <Field className="left-corner" /> : null}
                                {(player.position === 'RIGHTCORNER') ? <Field className="right-corner" /> : null}
                                {(player.position === 'ALLROUNDER') ? <Field className="all-rounder" /> : null}
                                {(player.position === 'RAIDER') ? <Field className="right-raider" /> : null}
                                {(player.position === 'DEFENDER') ? <Field className="left-cover" /> : null}



                                {(player.position === 'LEFTRAIDER') ? <p className="position">LEFT RAIDER</p> : null}
                                {(player.position === 'RIGHTRAIDER') ? <p className="position">RIGHT RAIDER</p> : null}
                                {(player.position === 'LEFTCOVER') ? <p className="position">LEFT COVER</p> : null}
                                {(player.position === 'RIGHTCOVER') ? <p className="position">RIGHT COVER</p> : null}
                                {(player.position === 'LEFTCORNER') ? <p className="position">LEFT CORNER</p> : null}
                                {(player.position === 'RIGHTCORNER') ? <p className="position">RIGHT CORNER</p> : null}
                                {(player.position === 'RAIDER') ? <p className="position">RAIDER</p> : null}
                                {(player.position === 'DEFENDER') ? <p className="position">DEFENDER</p> : null}
                                {(player.position === 'ALLROUNDER') ? <p className="position">ALL ROUNDER</p> : null}
                            </div>
                        </div>
                    </div> :
                    <div className="section-1-mobile">
                        <div className="group">
                            <div className="imagebox" ref={largeScreen ? null : image}>
                                <GradientImage image={CONSTANTS.createUrl(player.halfImage.url)} />
                            </div>
                            <p className="number">{player.squadNumber}</p>
                        </div>
                        <h1>{`${player.firstName} ${player.lastName}`}</h1>
                        <div className="row">
                            <div className="group">
                                {player.merchLink && <div className="jersey" ref={jerseybox}>
                                    <p>GET JERSEY</p>
                                    <div className="links">
                                        <a href={player.merchLink} target="_blank" rel="noreferrer">
                                            <Shirt />
                                        </a>
                                    </div>
                                </div>}
                                <div className="socials">
                                    <p>SOCIAL CHANNELS</p>
                                    <div className="links">
                                        {player.instagram && <a href={player.instagram} target="_blank" rel="noreferrer">
                                            <Instagram />
                                        </a>}
                                        {player.facebook && <a href={player.facebook} target="_blank" rel="noreferrer">
                                            <Facebook />
                                        </a>}
                                        {player.twitter && <a href={player.twitter} target="_blank" rel="noreferrer">
                                            <Twitter />
                                        </a>}
                                    </div>
                                </div>
                            </div>
                            <div className="group">
                                {(player.position === 'LEFTRAIDER') ? <Field className="left-raider" /> : null}
                                {(player.position === 'RIGHTRAIDER') ? <Field className="right-raider" /> : null}
                                {(player.position === 'LEFTCOVER') ? <Field className="left-cover" /> : null}
                                {(player.position === 'RIGHTCOVER') ? <Field className="right-cover" /> : null}
                                {(player.position === 'LEFTCORNER') ? <Field className="left-corner" /> : null}
                                {(player.position === 'RIGHTCORNER') ? <Field className="right-corner" /> : null}
                                {(player.position === 'ALLROUNDER') ? <Field className="all-rounder" /> : null}
                                {(player.position === 'RAIDER') ? <Field className="right-raider" /> : null}
                                {(player.position === 'DEFENDER') ? <Field className="left-cover" /> : null}


                                {(player.position === 'LEFTRAIDER') ? <p className="position">LEFT RAIDER</p> : null}
                                {(player.position === 'RIGHTRAIDER') ? <p className="position">RIGHT RAIDER</p> : null}
                                {(player.position === 'LEFTCOVER') ? <p className="position">LEFT COVER</p> : null}
                                {(player.position === 'RIGHTCOVER') ? <p className="position">RIGHT COVER</p> : null}
                                {(player.position === 'LEFTCORNER') ? <p className="position">LEFT CORNER</p> : null}
                                {(player.position === 'RIGHTCORNER') ? <p className="position">RIGHT CORNER</p> : null}
                                {(player.position === 'RAIDER') ? <p className="position">RAIDER</p> : null}
                                {(player.position === 'DEFENDER') ? <p className="position">DEFENDER</p> : null}
                                {(player.position === 'ALLROUNDER') ? <p className="position">ALL ROUNDER</p> : null}
                            </div>
                        </div>
                    </div>
                }

                <div className="section-2">
                    <div className="row">
                        <div className="item">
                            <p className="title">COUNTRY</p>
                            <p className="value">{player.country}</p>
                        </div>
                        <div className="item">
                            <p className="title">DATE OF BIRTH</p>
                            <p className="value">{`${format(new Date(player.dateOfBirth), 'do MMMM yyyy')} (${(calculateAge(player.dateOfBirth))} years old)`}</p>
                        </div>
                    </div>
                    <div className="main">
                        <img src={CONSTANTS.createUrl(player.fullImage.url)} alt={player.firstName + player.lastName} />
                        <div className="content">
                            <p className="title">ABOUT <span>{`${player.firstName.toUpperCase()} ${player.lastName.toUpperCase()}`}</span></p>
                            {player.aboutText.map(item =>
                                <p className="text">{item.value}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="section-3">
                    <div className="title">
                        <h2>STATISTICS</h2>
                    </div>
                    {(overallStats && attackingStats && defensiveStats) &&
                        <div className="main">
                            <div className="item">
                                <p className="title">OVERALL</p>
                                <PlayerStats data={overallStats} />
                            </div>
                            <div className="item">
                                <p className="title">ATTACKING</p>
                                <PlayerStats data={attackingStats} />
                            </div>
                            <div className="item">
                                <p className="title">DEFENSIVE</p>
                                <PlayerStats data={defensiveStats} />
                            </div>
                        </div>}
                </div>

                <div className="section-4">
                    <div className="title">
                        <h2>MORE PLAYERS</h2>
                    </div>
                    <div className="main">
                        <div className="inner">
                            <button
                                onClick={otherPlayersScrollBack}
                                disabled={otherPlayerScroll === 0}
                            >
                                <img src={Arrow} alt="" />
                            </button>
                            <a.div className="scroller" ref={otherPlayerRef} scrollLeft={otherPlayerStyles.scroll}>
                                {otherPlayers.map(item =>
                                    <Link to={`/squad/player/${item.id}`} className="item">
                                        <div className="imagebox">
                                            <img src={CONSTANTS.createUrl(item.halfImage.url)} alt={`${item.firstName} ${item.lastName}`} />
                                        </div>
                                        <div className="info">
                                            <div className="row">
                                                <p className="large">{item.squadNumber}</p>
                                                <p className="name">{`${item.firstName} \n${item.lastName}`}</p>
                                            </div>
                                            {(item.position === 'LEFTRAIDER') ? <p className="position">LEFT RAIDER</p> : null}
                                            {(item.position === 'RIGHTRAIDER') ? <p className="position">RIGHT RAIDER</p> : null}
                                            {(item.position === 'LEFTCOVER') ? <p className="position">LEFT COVER</p> : null}
                                            {(item.position === 'RIGHTCOVER') ? <p className="position">RIGHT COVER</p> : null}
                                            {(item.position === 'LEFTCORNER') ? <p className="position">LEFT CORNER</p> : null}
                                            {(item.position === 'RIGHTCORNER') ? <p className="position">RIGHT CORNER</p> : null}
                                            {(item.position === 'RAIDER') ? <p className="position">RAIDER</p> : null}
                                            {(item.position === 'DEFENDER') ? <p className="position">DEFENDER</p> : null}
                                            {(item.position === 'ALLROUNDER') ? <p className="position">ALL ROUNDER</p> : null}
                                        </div>
                                    </Link>
                                )}
                            </a.div>
                            <button
                                onClick={otherPlayersScrollForward}
                                disabled={otherPlayerRef.current && (otherPlayerScroll + otherPlayerRef.current.offsetWidth === otherPlayerRef.current.scrollWidth)}
                            >
                                <img src={Arrow} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (error || (data && !data.player.length)) {
        history.push('/squad');
        return <div />
    } else return <PageTransition />
}