import { useState, useEffect } from "react";
import "./Squad.scss";
import PageTransition from "../../Components/PageTransition/PageTransition";
import Hooda from "../../Assets/deepak.png";
import CONSTANTS from "../../Constants";
import Player from "./Player/Player";
import { Link, Route, Switch, Redirect } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

const SQUAD_QUERY = gql`
  query SquadQuery {
    squadPage {
      heroText
      subText
      heroImage {
        url
        alternativeText
      }
    }
    players(where: { isActive: true }, sort: "displayOrder:asc") {
      id
      squadNumber
      firstName
      lastName
      category
      position
      halfImage {
        url
        alternativeText
      }
    }
  }
`;

const RAIDERS = [
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
];

const DEFENDERS = [
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
];

const ALLROUNDERS = [
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
  {
    firstName: "DEEPAK",
    lastName: "HOODA",
    position: "LEFT RAIDER",
    img: Hooda,
    number: 44,
  },
];

export default function Squad({ setProgress }) {
  return (
    <Switch>
      <Route path="/squad" exact>
        <SquadMain setProgress={setProgress} />
      </Route>
      <Route path="/squad/player/:id">
        <Player setProgress={setProgress} />
      </Route>
      <Redirect to="/squad" />
    </Switch>
  );
}

function SquadMain({ setProgress }) {
  const [groupedRaiders, setGroupedRaiders] = useState(null);
  const [groupedDefenders, setGroupedDefenders] = useState(null);
  const [groupedAllRounders, setGroupedAllRounders] = useState(null);

  const { data, error, loading } = useQuery(SQUAD_QUERY);

  useEffect(() => {
    if (data) {
      const raidersArray = [];
      const defendersArray = [];
      const roundersArray = [];
      const RAIDERS = data.players.filter((item) => item.category === "RAIDER");
      const DEFENDERS = data.players.filter(
        (item) => item.category === "DEFENDER"
      );
      const ALLROUNDERS = data.players.filter(
        (item) => item.category === "ALLROUNDER"
      );

      for (
        let i = 0;
        i <
        data.players.filter((item) => item.category === "RAIDER").length / 3;
        i++
      ) {
        raidersArray.push([]);
      }
      RAIDERS.forEach((item, index) => {
        raidersArray[Math.floor(index / 3)].push(item);
      });

      for (let i = 0; i < DEFENDERS.length / 3; i++) {
        defendersArray.push([]);
      }
      DEFENDERS.forEach((item, index) => {
        defendersArray[Math.floor(index / 3)].push(item);
      });

      for (let i = 0; i < ALLROUNDERS.length / 3; i++) {
        roundersArray.push([]);
      }
      ALLROUNDERS.forEach((item, index) => {
        roundersArray[Math.floor(index / 3)].push(item);
      });

      setGroupedRaiders(raidersArray);
      setGroupedDefenders(defendersArray);
      setGroupedAllRounders(roundersArray);
      setProgress((prog) => prog + 60);
    }
  }, [data]);

  if (groupedRaiders) {
    return (
      <div className="squad-page">
        {data && data.squadPage ? (
          <div className="section-1">
            <img
              src={CONSTANTS.createUrl(data.squadPage.heroImage.url)}
              alt={data.squadPage.heroImage.alternativeText}
            />
            <h1>{data.squadPage.heroText}</h1>
            <h3>{data.squadPage.subText}</h3>
          </div>
        ) : null}
        <div className="section-2">
          <div className="subsection">
            <div className="title">
              <h2>RAIDERS</h2>
            </div>
            <div className="main">
              {groupedRaiders.map((row) => (
                <div
                  className="row"
                  style={{
                    justifyContent:
                      row.length === 3 ? "space-between" : "space-evenly",
                  }}
                >
                  {row.map((item) => (
                    <Link to={`/squad/player/${item.id}`} className="item">
                      <div className="imagebox">
                        <img
                          src={CONSTANTS.createUrl(item.halfImage.url)}
                          alt={`${item.firstName} ${item.lastName}`}
                        />
                        <div className="number">
                          <p>{item.squadNumber}</p>
                        </div>
                      </div>
                      <div className="textbox">
                        <p>{item.firstName.toUpperCase()}</p>
                        <p>{item.lastName.toUpperCase()}</p>
                        {item.position === "LEFTRAIDER" ? (
                          <p className="position">LEFT RAIDER</p>
                        ) : null}
                        {item.position === "RIGHTRAIDER" ? (
                          <p className="position">RIGHT RAIDER</p>
                        ) : null}
                        {item.position === "RAIDER" ? (
                          <p className="position">RAIDER</p>
                        ) : null}
                      </div>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="subsection">
            <div className="title">
              <h2>ALL-ROUNDERS</h2>
            </div>
            <div className="main">
              {groupedAllRounders.map((row) => (
                <div
                  className="row"
                  style={{
                    justifyContent:
                      row.length === 3 ? "space-between" : "space-evenly",
                  }}
                >
                  {row.map((item) => (
                    <Link to={`/squad/player/${item.id}`} className="item">
                      <div className="imagebox">
                        <img
                          src={CONSTANTS.createUrl(item.halfImage.url)}
                          alt={`${item.firstName} ${item.lastName}`}
                        />
                        <div className="number">
                          <p>{item.squadNumber}</p>
                        </div>
                      </div>
                      <div className="textbox">
                        <p>{item.firstName.toUpperCase()}</p>
                        <p>{item.lastName.toUpperCase()}</p>
                        <p className="position">ALL ROUNDER</p>
                      </div>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="subsection">
            <div className="title">
              <h2>DEFENDERS</h2>
            </div>
            <div className="main">
              {groupedDefenders.map((row) => (
                <div
                  className="row"
                  style={{
                    justifyContent:
                      row.length === 3 ? "space-between" : "space-evenly",
                  }}
                >
                  {row.map((item) => (
                    <Link to={`/squad/player/${item.id}`} className="item">
                      <div className="imagebox">
                        <img
                          src={CONSTANTS.createUrl(item.halfImage.url)}
                          alt={`${item.firstName} ${item.lastName}`}
                        />
                        <div className="number">
                          <p>{item.squadNumber}</p>
                        </div>
                      </div>
                      <div className="textbox">
                        <p>{item.firstName.toUpperCase()}</p>
                        <p>{item.lastName.toUpperCase()}</p>
                        <>
                          {item.position === "LEFTCOVER" ? (
                            <p className="position">LEFT COVER</p>
                          ) : null}
                          {item.position === "LEFTCORNER" ? (
                            <p className="position">LEFT CORNER</p>
                          ) : null}
                          {item.position === "RIGHTCOVER" ? (
                            <p className="position">RIGHT COVER</p>
                          ) : null}
                          {item.position === "RIGHTCORNER" ? (
                            <p className="position">RIGHT CORNER</p>
                          ) : null}
                          {item.position === "DEFENDER" ? (
                            <p className="position">DEFENDER</p>
                          ) : null}
                        </>
                      </div>
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else return <PageTransition />;
}
