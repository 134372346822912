import "./Footer.scss";
import Panther from "../../Assets/pink-panther-logo.png";
import Instagram from "../../Assets/Icons/instagram.svg";
import Facebook from "../../Assets/Icons/facebook.svg";
import Youtube from "../../Assets/Icons/youtube.svg";
import Twitter from "../../Assets/Icons/twitter.svg";
import Phone from "../../Assets/footer-phone.png";
import GooglePlay from "../../Assets/Icons/google-play.svg";
import Apple from "../../Assets/Icons/apple.svg";
import Agnostech from "../../Assets/Footer/agnlogo1.png";
import AskMedia from "../../Assets/Footer/asklogo1.png";
import Aviate from "../../Assets/Footer/avlogo1.png";
import MadeWithLove from "../../Assets/Footer/made_with_love.png";
import { Link, useHistory } from "react-router-dom";

export default function Footer({
  data,
  setOpen,
  loggedIn,
  user,
  ticketsPage,
  merchPage,
  showAppSection,
  isSafari,
}) {
  const history = useHistory();
  return (
    <div className="footer">
      <img src={Panther} alt="" />
      <div className="section-1">
        <p className="light">PINK PANTHERS ON SOCIAL CHANNELS</p>
        {data && (
          <div className="socials">
            {data.instagram && (
              <a href={data.instagram} target="_blank" rel="noreferrer">
                <img src={Instagram} alt="" />
              </a>
            )}
            {data.facebook && (
              <a href={data.facebook} target="_blank" rel="noreferrer">
                <img src={Facebook} alt="" />
              </a>
            )}
            {data.youtube && (
              <a href={data.youtube} target="_blank" rel="noreferrer">
                <img src={Youtube} alt="" />
              </a>
            )}
            {data.twitter && (
              <a href={data.twitter} target="_blank" rel="noreferrer">
                <img src={Twitter} alt="" />
              </a>
            )}
          </div>
        )}
        <p className="copyright">© JAIPUR PINK PANTHERS</p>
      </div>
      <div className="section-2">
        <p className="title">QUICK LINKS</p>
        <div className="row">
          <div className="links">
            <div className="item">
              <Link to="/squad">Squad</Link>
            </div>
            <div className="item">
              <Link to="/matches">Matches</Link>
            </div>
            <div className="item">
              <Link to="/terms">Terms & Conditions</Link>
            </div>
            <div className="item">
              <Link to="/standings">Standings</Link>
            </div>
            <div className="item">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            {merchPage ? (
              <div className="item">
                <Link to="/merchandise">Merchandise</Link>
              </div>
            ) : null}
            <div className="item">
              <Link to="/panther-pack">Panthers Pack</Link>
            </div>
            {loggedIn && user ? null : (
              <div className="item">
                <Link
                  to="/login"
                  onClick={(e) => {
                    e.preventDefault();
                    if (isSafari) {
                      history.push("/login");
                    } else {
                      setOpen(true);
                    }
                  }}
                >
                  Log In
                </Link>
              </div>
            )}
            {ticketsPage ? (
              <div className="item">
                <Link to="/tickets">Tickets</Link>
              </div>
            ) : null}
            <div className="item">
              <Link to="/news">Latest News</Link>
            </div>
          </div>
          {showAppSection ? (
            <div className="mobile-links">
              <img src={Phone} alt="" />
              <div className="column">
                <p>Download the official panthers app</p>
                {data && (
                  <div className="row">
                    {data.playStoreLink && (
                      <a
                        href={data.playStoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={GooglePlay} alt="" />
                      </a>
                    )}
                    {data.appStoreLink && (
                      <a
                        href={data.appStoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Apple} alt="" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="section-3">
        <img src={MadeWithLove} alt="" />
        <a
          href="https://agnostech.in/"
          className="medium"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Agnostech} alt="" />
        </a>
        <a
          href="https://askmediacorp.com/"
          className="large"
          target="_blank"
          rel="noreferrer"
        >
          <img src={AskMedia} alt="" />
        </a>
        <a
          href=""
          onClick={(e) => e.preventDefault()}
          style={{ cursor: "default" }}
        >
          <img src={Aviate} alt="" />
        </a>
      </div>
    </div>
  );
}
