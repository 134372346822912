import {useState, useEffect} from 'react';
import './Dropdown.scss';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {ReactComponent as DropdownArrow} from '../../../Assets/Icons/dropdown-arrow.svg';

const useLargeScreenStyles = makeStyles((theme) => ({
    selectRoot: {
        color: 'white',
        fontFamily: 'var(--primaryFont)',
        fontSize: '1.2rem',
        padding: '0.3rem 0 0 1.5rem',
        minHeight: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },

    selectSelect: {
        paddingRight: '0',

        '&.MuiSelect-select': {
            paddingRight: '0'
        }
    },

    paperRounded: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },

    selectMenu: {
        maxHeight: '10rem',

        '& .MuiMenuItem-root': {
            color: 'black',
            fontFamily: 'var(--primaryFont)',
            fontSize: '1.2rem',

            '&:hover, &.Mui-selected': {
                background: 'var(--primaryColor)',
                color: 'white'
            }
        }
    },

    icon: {
        height: '0.5rem',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '1.5rem',
        '&>path': {
            stroke: 'white',
        }
    }
}));

const useSmallScreenStyles = makeStyles((theme) => ({
    selectRoot: {
        color: 'white',
        fontFamily: 'var(--primaryFont)',
        fontSize: '1.8rem',
        padding: '0.45rem 0 0 2.25rem',
        minHeight: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },

    selectSelect: {
        paddingRight: '0',

        '&.MuiSelect-select': {
            paddingRight: '0'
        }
    },

    paperRounded: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },

    selectMenu: {
        maxHeight: '15rem',

        '& .MuiMenuItem-root': {
            color: 'black',
            fontFamily: 'var(--primaryFont)',
            fontSize: '1.8rem',

            '&:hover, &.Mui-selected': {
                background: 'var(--primaryColor)',
                color: 'white'
            }
        }
    },

    icon: {
        height: '0.75rem',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '2.25rem',
        '&>path': {
            stroke: 'white',
        }
    }
}));

export default function Dropdown(props){
    const largeClasses = useLargeScreenStyles();
    const smallClasses = useSmallScreenStyles();
    const [largeScreen, setLargeScreen] = useState(0); // Boolean, when true, show desktop styles

    useEffect(() => {
        const setSize = (event) => {
            setLargeScreen(event.target.innerWidth >= 500);
        }
        setLargeScreen(window.innerWidth >= 500);
        window.addEventListener('resize', setSize);
        return () => {
            window.removeEventListener('resize', setSize);
        }
    }, []);

    return(
        <div className="transparent-dropdown">
            <Select
                value={props.value}
                onChange={(e) => props.setValue(e.target.value)}
                label="Age"
                disableUnderline
                MenuProps={{
                    classes: {list: largeScreen? largeClasses.selectMenu : smallClasses.selectMenu}, 
                    transitionDuration: 0,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {classes: {rounded: largeScreen? largeClasses.paperRounded : smallClasses.paperRounded}}
                }}
                inputProps={{classes: {root: largeScreen? largeClasses.inputRoot : smallClasses.inputRoot}}}
                classes={{
                    root: largeScreen? largeClasses.selectRoot : smallClasses.selectRoot, 
                    icon: largeScreen? largeClasses.icon : smallClasses.icon, 
                    select: largeScreen? largeClasses.selectSelect : smallClasses.selectSelect, 
                    selectMenu: largeScreen? largeClasses.selectMenu : smallClasses.selectMenu
                }}
                IconComponent={DropdownArrow}
            >
                {props.options.map((item, index) => 
                    <MenuItem value={item.value}>{item.text}</MenuItem>
                )}
            </Select>
        </div>
    )
}