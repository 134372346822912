import { useState, useEffect, useRef } from 'react';
import './App.css';
import HomePage from './Pages/HomePage/HomePage';
import Merchandise from './Pages/Merchandise/Merchandise';
import News from './Pages/News/News';
import Standings from './Pages/Standings/Standings';
import Squad from './Pages/Squad/Squad';
import Matches from './Pages/Matches/Matches';
import PanthersPack from './Pages/PanthersPack/PanthersPack';
import Tickets from './Pages/Tickets/Tickets';
import Terms from './Pages/TermsAndPrivacy/Terms';
import PrivacyPolicy from './Pages/TermsAndPrivacy/PrivacyPolicy';
import LoginPage from './Pages/LoginPage/LoginPage';
import LoadingPage from './Components/LoadingPage/LoadingPage';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import SideNav from './Components/SideNav/SideNav';
import Login from './Components/Login/Login';
import PageTransition from './Components/PageTransition/PageTransition';
import AuthCallback from './Pages/AuthCallback';
import { Dialog, makeStyles } from '@material-ui/core';
import {Route, Switch, Redirect, useHistory, useLocation} from 'react-router-dom';
import {useQuery, useLazyQuery, gql, useApolloClient} from '@apollo/client';
import axios from 'axios';

const APP_QUERY = gql`
query HomeQuery{
    homePage{
        showAppSection
        facebook,
        instagram,
        twitter,
        youtube,
        playStoreLink,
        appStoreLink
    },
    merchPage{
        isActive
    },
    ticketsPage{
        isActive
    }
    sponsors(where: {isTitleSponsor:true}){
        id,
        url,
        image{
            url,
            alternativeText
        }
    }
}`;

const USER_CONFIRM_QUERY = gql`
query UserCheck{
    me{
        id,
        email,
        username,
    }
}`;

const USER_UPDATE_QUERY = gql`
query UserUpdateQuery($id: ID!){
    user(id: $id){
        id,
        username,
        provider,
        socialMedia,
        socialMediaHandle,
        confirmed,
        blocked,
        email,
        quizzes{
            id
        },
        quiz_responses{
            id
        },
        created_at,
        updated_at
    }
}`;

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        backgroundColor: 'transparent',
    },
    backdropRoot: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
    }
}));

function App() {
    const now = useRef(new Date());
    const [isSafari, setIsSafari] = useState();
    const [sidenavOpen, setSidenavOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [loginPageNumber, setLoginPageNumber] = useState(1);
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [showLoading, setShowLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    const client = useApolloClient();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const {data, loading, error} = useQuery(APP_QUERY);
    const {data: userData, loading: userLoading, error: userError, refetch} = useQuery(USER_CONFIRM_QUERY);
    const [getUser, {data: updatedUserData}] = useLazyQuery(USER_UPDATE_QUERY)

    useEffect(() => {
        const string = navigator.userAgent;
        setIsSafari(!(string.indexOf('Chrome') > -1)&&(string.indexOf('Safari') > -1));
        document.body.style.webkitTouchCallout='none';
    }, [])

    useEffect(() => {
        if(!window.localStorage.getItem('jwt')){
            setProgress(prog => prog + 20);
        };
        if(userData || userError){
            setProgress(prog => prog + 20);
        }
        if(userData && userData.me && userData.me.email){
            setLoggedIn(true);
            // setUser(userData.me)
            getUser({
                variables: {
                    id: userData.me.id
                }
            })
            setLoginPageNumber(2);
        }
    },[userData]);

    useEffect(() => {
        if(updatedUserData){
            setUser(updatedUserData.user);
        }
    },[updatedUserData])

    useEffect(() => {
        if((new URL(document.location).searchParams.get('login') === 'true') && !isSafari){
            if(showLoading){
                return;
            } else{
                setLoginOpen(true);
            }
        }
    }, [location]);


    useEffect(() => {
        if(data){
            setProgress(prog => prog + 20);
        }
    },[data]);

    useEffect(() => {
        var timeout;
        if((progress >= 100) && showLoading){
            setTimeout(() => {
                setShowLoading(false);
                if((new URL(document.location).searchParams.get('login') === 'true') && (!isSafari || (isSafari && loginPageNumber === 2))){
                    setTimeout(() => {
                        setLoginOpen(true);
                    }, 500)
                }
            }, 200);
        };

        return () => clearTimeout(timeout);
    }, [progress]);

    useEffect(() => {
        const callback = () => {
            refetch();
        }
        window.addEventListener('storage', callback);
        return () => window.removeEventListener('storage', callback);
    },[]);

    const logout = () => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        client.clearStore();
        setLoggedIn(false);
        setUser(null);
        window.location.reload();
    }

    const handleClose = () => {
        setShowLoading(false);
    }

    return (
        <div className="App">
            <Header 
                setLoginOpen={setLoginOpen} 
                setPageNumber={setLoginPageNumber}
                loggedIn={loggedIn} 
                user={user}
                logout={logout}
                isSafari={isSafari}
                setSidenavOpen={setSidenavOpen}
                ticketsPage={data && data.ticketsPage.isActive}
                merchPage={data && data.merchPage.isActive}
                titleSponsor={(data && data.sponsors.length) && data.sponsors[0]}
            />
            {(data && (isSafari === true || isSafari === false))?
                <Switch>
                    <Route path="/home">
                        <HomePage 
                            setOpen={setLoginOpen} 
                            user={user} 
                            loggedIn={loggedIn}
                            setProgress={setProgress}
                            now={now}
                            isSafari={isSafari}
                        />
                    </Route>
                    {(data && data.merchPage.isActive)? 
                        <Route path="/merchandise">
                            <Merchandise setProgress={setProgress}/>
                        </Route> 
                        : null
                    }
                    <Route path="/news">
                        <News setProgress={setProgress}/>
                    </Route>
                    <Route path="/standings">
                        <Standings setProgress={setProgress} now={now}/>
                    </Route>
                    <Route path="/squad">
                        <Squad setProgress={setProgress}/>
                    </Route>
                    <Route path="/matches">
                        <Matches setProgress={setProgress} now={now}/>
                    </Route>
                    <Route path="/panther-pack">
                        <PanthersPack 
                            loggedIn={loggedIn} 
                            user={user} 
                            setOpen={setLoginOpen} 
                            setProgress={setProgress}
                            isSafari={isSafari}
                        />
                    </Route>
                    {(data && data.ticketsPage.isActive)?
                        <Route path="/tickets">
                            <Tickets setProgress={setProgress}/>
                        </Route>
                        : null
                    }
                    {(isSafari && !loggedIn) ? 
                        <Route path="/login">
                            <LoginPage setProgress={setProgress}/>
                        </Route> : null
                    }
                    <Route path="/connect/:provider/redirect">
                        <AuthCallback isSafari={isSafari}/>
                    </Route>
                    <Route path="/terms">
                        <Terms setProgress={setProgress}/>
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy setProgress={setProgress}/>
                    </Route>
                    <Redirect to="/home" />
                </Switch>
                : <PageTransition />
            }
            <Login 
                open={loginOpen} 
                setOpen={setLoginOpen} 
                pageNumber={loginPageNumber} 
                setPageNumber={setLoginPageNumber} 
                loggedIn={loggedIn}
                user={user}
                isSafari={isSafari}
            />
            {/* <Dialog
                open={showLoading}
                onClose={handleClose}
                BackdropProps={{classes: {root: classes.backdropRoot}}}
                PaperProps={{classes: {root: classes.paperRoot}}}
                fullScreen
            >
                <LoadingPage progress={progress}/>
            </Dialog> */}
            <SideNav 
                open={sidenavOpen} 
                setOpen={setSidenavOpen} 
                ticketsPage={data && data.ticketsPage.isActive}
                merchPage={data && data.merchPage.isActive}
            />
            <Footer 
                isSafari={isSafari}
                data={data&&data.homePage} 
                setOpen={setLoginOpen} 
                user={user} 
                loggedIn={loggedIn}
                ticketsPage={data && data.ticketsPage.isActive}
                merchPage={data && data.merchPage.isActive}
                showAppSection={data && data.homePage.showAppSection}
            />
        </div>
    );
}

export default App;
