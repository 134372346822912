import {useState, useRef, useEffect} from 'react';
import './PlayerStats.scss';
import {useSpring, a, config} from 'react-spring';

export default function PlayerStats({data}){
    const [animate, setAnimate] = useState(false); // Starts animations when true
    const largeValue  = useSpring({number: animate? data.largeValue : 0, config: {mass: 1, tension: 150, friction: 50}});
    const radialValue  = useSpring({number: animate? data.radialValue : 0, config: {mass: 1, tension: 100, friction: 40, clamp: true}});
    const angle = useSpring({number: animate? (data.radialValue === 100? 6.28255 : Math.PI * data.radialValue/50) : 0, config: {mass: 1, tension: 100, friction: 40, clamp: true}})

    console.log(data);

    const ReturnSprings = (value, animate, index) => {
        return useSpring({number: animate? value : 0, config: config.molasses, delay: index * 200})
    };

    const linearSprings = data.linear.map((item, index) => ReturnSprings(item.value, animate));

    const element = useRef();


    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const callback = (entries) => {
            if(!animate && entries[0].isIntersecting){
                setAnimate(true);
            }            
        }
            
        const observer = new IntersectionObserver(callback, options);
        observer.observe(element.current)
    },[]);

    return(
        <div className="player-stat-card" ref={element}>
            <div className="row-1">
                <div className="number-item">
                    <p className="title">{data.largeTitle}</p>
                    <a.p className="value">{largeValue.number.to(n => n.toFixed(0))}</a.p>
                </div>
                <div className="percent-item">
                    <p className="title">{data.radialTitle}</p>
                    <div className="indicator">
                        <a.svg viewBox="0 0 100 100">
                            <circle cx="50" cy="50" fill="#3A4F99" r="50"/>
                            <a.path 
                                d={angle.number.to(x => `M50,50 L50,100 A50 50 0 ${x >= Math.PI ? 1 : 0} 1 ${50 * (1 - Math.sin(x))} ${50 * (1 + Math.cos(x))} Z`)}
                                fill="var(--primaryColor)"
                            />
                            <circle cx="50" cy="50" fill="var(--secondaryColor)" r="40"/>
                        </a.svg>
                        <a.p>{radialValue.number.to(n => n.toFixed(2) + '%')}</a.p>
                    </div>
                </div>
            </div>
            <div className="row-2">
                {data.linear.map((item, index) => 
                    <div className="item">
                        <div className="title">
                            <p className="label">{item.title}</p>
                            <a.p className="value">{linearSprings[index].number.to(x => 
                                 x.toFixed(Number.isInteger(item.value)? 0 : 2).toString().padStart(2, '0')
                            )}</a.p>
                        </div>
                        <div className="indicator">
                            <a.div className="value" style={{width: linearSprings[index].number.to(x => 100*x/item.maxValue + '%')}}/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}