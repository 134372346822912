export const reverseNameMatchesIndex = [0, 1, 2, 4, 7, 13, 17, 16, 18, 21]
export const reverseMatchesIdSeason11 = ['205', '206', '207', '210', '212', '217', '218', '222', '226']
export const previousMatchId = {
    'Haryana Steelers': '204',
    'Gujarat Giants': '183',
    'U.P. Yoddhas': '187',
    'Patna Pirates': '189',
    'Tamil Thalaivas': '194',
    'Bengaluru Bulls': '192',
    'Bengal Warriorz': '196',
    'Puneri Paltan': '199',
    'Telugu Titans': '198',
    'Dabang Delhi K.C.': '190',
    'U Mumba': '201'
}