import './NewsItem.scss';
import { useParams } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import {format} from 'date-fns';
import CONSTANTS from '../../Constants';
import PageTransition from '../../Components/PageTransition/PageTransition';
import ReactMarkdown from 'react-markdown';
import rehype from 'rehype-raw';
import gfm from 'remark-gfm';
import { Redirect } from 'react-router-dom';

const NEWS_ITEM_QUERY = gql`
query NewsQuery($id: ID!){
    newsItem(id: $id){
        title,
        image{
            url,
            alternativeText
        },
        text,
        fullText,
        isWide,
        date,
        news_label{
            id,
            name
        }
    }
}`;


export default function NewsItem(){

    const {id} = useParams();
    const {data, error, loading} = useQuery(NEWS_ITEM_QUERY, {
        variables: {
            id
        }
    });

    console.log(data);

    if(data && data.newsItem){
        if(data.newsItem.fullText){
            return(
                <div className="news-item-page">
                    <div className="section-1">
                        <div className="text">
                            <h1>{data.newsItem.title}</h1>
                            <div className="row">
                                <p className="date">{format(new Date(data.newsItem.date), 'dd MMM yy')}</p>
                                <p className="tag">{data.newsItem.news_label.name}</p>
                            </div>
                        </div>
                        {data.newsItem? <img src={CONSTANTS.createUrl(data.newsItem.image.url)} alt="" /> : null}
                    </div>
                    <div className="section-2">
                        <p className="subtitle">{data.newsItem.text}</p>
                        <ReactMarkdown skipHtml remarkPlugins={[gfm]} rehypePlugins={[rehype]}>{data.newsItem.fullText}</ReactMarkdown>
                    </div>
                </div>
            )
        } else return <Redirect to="/news" /> 
    } else if(error){
        return <Redirect to="/news" /> 
    } else return <PageTransition />
}