import { useState, useEffect } from 'react';
import './ImageGallery.scss';
import Image from '../../../Assets/LandingImage.jpg';
import Image2 from '../../../Assets/pantherspacklarge.png';
import GaleryModal from '../../../Components/UI/GalleryModal/GalleryModal';
import { useParams, useHistory } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import CONSTANTS from '../../../Constants';
import PageTransition from '../../../Components/PageTransition/PageTransition';

const IMAGE_GALLERY_QUERY = gql`
query ImageGalleryQuery($id: ID!){
    imageGallery(id:$id) {
        title,
        text,
        images{
            url,
            alternativeText
        }
    }
}`;

const VIDEO_GALLERY_QUERY = gql`
query VideoGalleryQuery($id: ID!){
    videoGallery(id:$id) {
        title,
        text,
        videoUrls{
            value,
            thumbnailUrl
        }
    }
}`;

const ITEMS = [
    {type: 'image', src: Image},
    {type: 'image', src: Image2},
    {type: 'video', src: 'Cub2jcRwj_Q'},
    {type: 'image', src: Image},
    {type: 'image', src: Image2},
    {type: 'video', src: 'Cub2jcRwj_Q'},
    {type: 'image', src: Image},
    {type: 'image', src: Image2},
    {type: 'video', src: 'Cub2jcRwj_Q'},
    {type: 'image', src: Image},
    {type: 'image', src: Image2},
    {type: 'video', src: 'Cub2jcRwj_Q'},
    {type: 'image', src: Image},
    {type: 'image', src: Image2},
    {type: 'video', src: 'Cub2jcRwj_Q'},
]

export default function ImageGallery({type, loggedIn, user, setProgress, isSafari}){
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const {id} = useParams();
    const history = useHistory();
    const {data, error, loading} = useQuery(((type === 'image')? IMAGE_GALLERY_QUERY : VIDEO_GALLERY_QUERY), {variables: {
        id: parseInt(id)
    }});
    console.log(data);

    useEffect(() => {
        if(data){
            setProgress(prog => prog + 60);
        }
    })

    if(!(user || loggedIn)){
        if(isSafari){
            history.push("/login");
        } else{
            history.push("/panther-pack?login=true");
        }
    }

    if(data && ((type === 'image')?data.imageGallery.images.length:data.videoGallery.videoUrls.length)){
        return(
            <div className="image-gallery">
                <div className="title">
                    {type==='image'?
                        <h1>{data.imageGallery.title}</h1>
                        :<h1>{data.videoGallery.title}</h1>
                    }
                    {type==='image'?
                        <p className="text">{data.imageGallery.text}</p>
                        :<p className="text">{data.videoGallery.text}</p>
                    }
                </div>
                <div className="gallery">
                    {type==='image'?
                        data.imageGallery.images.map((item, index) => 
                            <img 
                                src={CONSTANTS.createUrl(item.url)} 
                                alt={item.alternativeText}
                                onClick={() => {
                                setSelectedIndex(index)
                                setOpen(true)
                            }}/>
                        ): data.videoGallery.videoUrls.map((item, index) => 
                            <img 
                                src={item.thumbnailUrl} 
                                alt=""
                                onClick={() => {
                                setSelectedIndex(index)
                                setOpen(true)
                            }}/>
                        )
                        // {ITEMS.map((item, index) => 
                        //     <img 
                        //         src={(item.type === 'image')? item.src : `http://img.youtube.com/vi/${item.src}/0.jpg`} 
                        //         alt=""
                        //         onClick={() => {
                        //         setSelectedIndex(index)
                        //         setOpen(true)
                        //     }}/>
                        // )}
                    }
                </div>
                <GaleryModal 
                    open={open} 
                    setOpen={setOpen} 
                    data={(type==='image')?data.imageGallery.images:data.videoGallery.videoUrls} 
                    selectedIndex={selectedIndex} 
                    setSelectedIndex={setSelectedIndex}
                    type={type}
                />
            </div>
        )
    } else if(error || (data&&((type === 'image')?!data.imageGallery.images.length:!data.videoGallery.videoUrls.length))){
        history.push("/panther-pack");
        return <div />
    }else return <PageTransition/>
}